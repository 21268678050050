.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-weight: 400;
    font-size: 17px;
    color: var(--cl-black);
    line-height: 1.4;
    padding-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      color: var(--cl-primary);
    }
  }
}

.dark-bg {
  .meta-input,
  .meta-select,
  .meta-upload-file {
    label,
    .label {
      color: var(--cl-white);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-weight: 400;
    font-size: ac(17px, 18px);
    color: var(--cl-black);
    font-style: normal;
    line-height: normal;
    border: 1px solid #d2d2d2;
    border-radius: 7px;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 18px ac(20px, 25px);
    height: ac(70px, 64px);
    background: var(--cl-white);
    @mixin transparent-bg-input var(--cl-black);
    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

    &:focus {
      border-color: var(--cl-navy);
      caret-color: var(--cl-navy);
      box-shadow: 2px 2px 5px 0px rgba(114, 116, 115, 0.3);
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    padding-top: 16px;
    min-height: ac(78px, 90px);
    max-height: ac(78px, 90px);
    overflow-y: auto;
    resize: none;
    clip-path: inset(-1px -1px -1px -1px round ac(39px, 28px));
    border-radius: ac(39px, 28px);

    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-primary);
      border-radius: 2px;
    }
  }

  &.dark-border {
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 700;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  &.form-type {
    input,
    textarea {
      border-radius: 8px;
      background-color: var(--cl-light-bg);
      border: 1px solid var(--cl-aqua);
      padding-left: 19px;
      padding-right: 19px;
      font-size: ac(18px, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--cl-navy-80);
      @mixin transparent-bg-input var(--cl-navy-80);

      &::placeholder {
        color: var(--cl-navy-80);
      }
    }
  }

  &.profile-type {
    input,
    textarea {
      border-radius: 8px;
      background-color: var(--cl-white);
      border: 1px solid rgba(53, 53, 63, 0.3);
      padding-left: 19px;
      padding-right: 19px;
      font-size: ac(18px, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--cl-charcoal);
      @mixin transparent-bg-input var(--cl-charcoal);

      &::placeholder {
        color: var(--cl-charcoal);
        opacity: 0.8;
      }

      &:focus {
        border-color: var(--cl-navy-80);
      }
    }

    textarea {
      clip-path: inset(-1px -1px -1px -1px round 8px);
      border-radius: 8px;
      min-height: ac(184px, 90px);
      max-height: ac(184px, 90px);
    }
  }
}

.dark-bg {
  .meta-input {
    input,
    textarea {
      &:focus {
        border-color: var(--cl-aqua);
      }
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  select {
    opacity: 0;
    height: ac(56px, 48px);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &__inner {
      border: 1px solid #d2d2d2;
      border-radius: 7px;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: ac(19px, 12px) ac(30px, 22px) !important;
      background: var(--cl-white);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      font-size: ac(21px, 18px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--cl-black);
      height: ac(70px, 64px);
      min-height: ac(70px, 64px);
      &:hover {
        box-shadow: 2px 2px 5px 0px rgba(131, 175, 157, 0.3);
      }
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__inner {
          border-radius: 7px 7px 0 0;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          border-top: none;
          margin-top: 0;
          border-radius: 0 0 7px 7px;
        }
      }

      &.is-flipped {
        .choices__inner {
          border-radius: 0 0 7px 7px;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 0;
          border-bottom: none;
          border-radius: 7px 7px 0 0;
        }
      }
    }

    &__item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__placeholder {
      color: var(--cl-black);
      opacity: 1;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-white);

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: ac(21px, 18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--cl-black);

        &.is-selected {
          color: var(--cl-primary);
        }
      }

      /*&--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }*/

      &--dropdown {
        .choices__item {
          color: var(--cl-black);
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-navy);
        border-right: 1px solid var(--cl-navy);
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        margin-top: -6px;
        pointer-events: none;
        position: absolute;
        right: ac(35px, 20px);
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(1.5);
        transition: all 0.15s ease-in-out;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(1.5);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;
      &:not(.is-selected) {
        color: var(--cl-primary);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 1px solid #D2D2D2;
      z-index: 20;
      border-radius: 6px;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 2px solid var(--cl-lilac-500);
      border-radius: 8px;
      background: transparent;
      color: var(--cl-primary);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &.sort-type {
    .choices {
      .choices__inner {
        border-color: rgba(53, 53, 63, 0.3);
        background-color: var(--cl-white);
      }

      .choices__list {
        background-color: var(--cl-white);
      }

      .choices__list.choices__list--single {
        .choices__item.choices__item--selectable {
          /*padding-left: 120px !important;
          position: relative;*/

          &:before {
            display: inline-block;
            content: 'Sort by ‒';
            margin-right: 4px;
          }
        }
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        &:after {
          border-color: var(--cl-charcoal);
        }
      }
    }
  }

  &.profile-type {
    .choices {
      .choices__inner {
        border-color: rgba(53, 53, 63, 0.3);
        background-color: var(--cl-white);
      }

      .choices__list {
        background-color: var(--cl-white);
      }

      .choices__list.choices__list--single {
        .choices__item.choices__item--selectable {
          /*padding-left: 120px !important;
          position: relative;*/

          color: var(--cl-charcoal);
          opacity: 0.8;
        }
      }
    }
  }
}

.password-input {
  position: relative;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    right: ac(20px, 14px);
    bottom: ac(16px, 12px);
    position: absolute;
    z-index: 10;
    --size: ac(24px, 22px);
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    color: var(--cl-charcoal);
    transition: 0.3s;

    i {
      font-size: 16px;

      &.icon-not-show {
        font-size: 18px;
      }
    }

    &:hover {
      color: var(--cl-navy);
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  label,
  .label {
    color: #757575;
    padding-bottom: ac(16px, 12px);
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    border: 1px solid var(--cl-navy);
    padding: ac(14px, 10px) ac(28px, 24px);
    border-radius: 12px;
    transition: all 0.3s ease;
    background: var(--cl-white);
    cursor: pointer;
    position: relative;
    height: ac(56px, 48px);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-navy);
      transition: 0.3s;
    }

    &:hover {
      background-color: var(--cl-aqua);
      border-color: var(--cl-charcoal);
      color: var(--cl-charcoal);

      span {
        color: var(--cl-charcoal);
      }
    }
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-style: italic;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-primary);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid var(--cl-blue);
  padding: 5px 14px;
  width: fit-content;
  transition: .3s all;
  &:not(:last-child) {
    margin-bottom: ac(7px, 6px);
  }
  &:hover {
    border-color: var(--cl-navy);
  }
  &__box {
    --size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border: 1px solid var(--cl-navy);
    margin-right: ac(7px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;
    &:hover {
      border-color: var(--cl-navy-80);
    }

    /* .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-black);
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    } */

    /* .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    } */

    i {
      opacity: 0;
      font-size: 9px;
      color: var(--cl-navy);
      transition: opacity 0.3s ease, color 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-size: ac(21px, 18px);
    font-weight: 400;
    line-height: 1.35;
    font-family: var(--font-main);
    color: var(--cl-black);
    a {
      font: inherit;
      color: var(--cl-blue);
      text-decoration: underline;
      text-decoration-color: var(--cl-blue);
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-aqua);
        text-decoration-color: var(--cl-blue-80);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-navy);

    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
      color: var(--cl-navy);
    }

    &:hover {
      border-color: var(--cl-blue);
    }
  }
}


input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}