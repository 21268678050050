.partner {
  padding-top: ac(30px, 20px);
  padding-bottom: ac(130px, 64px);
  overflow: hidden;
  &-decor {
    position: absolute;
    z-index: -1;
    top: ac(0px, 92px);
    left: 50%;
    width: 100%;
    min-width: 560px;
    transform: translateX(-50%);
    max-width: ac(1333px, 992px, 640);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    margin-bottom: ac(70px, 32px);
    font-weight: 500;
    font-size: ac(70px, 32px);
    line-height: 1.28;
    color: var(--cl-black);
  }

  &-wrap {
    .swiper {
      overflow: visible;
      @media (max-width: 1300px) {
        max-width: 398px;
        margin: 0;
      }
    }
  }

  /* card => _approach.pcss */
}