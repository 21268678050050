.case-studies {
  margin-bottom: ac(80px, 42px);
  &__wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(ac(300px, 340px), 1fr));
    gap: ac(26px, 18px);
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__card {
    border-radius: 16px;
    padding: ac(40px, 18px);
    padding-top: ac(38px, 18px);
    padding-bottom: ac(32px, 18px);
    background: #FFF;
    border: 1px solid var(--cl-sky-blue);
    min-height: ac(498px, 380px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: .35s all;
    max-width: 478px;
    height: fit-content;
    @media (max-width: 1024px) {
    }
    @media (max-width: 640px) {
      max-width: none;
    }
    &:hover {
      border-color: var(--cl-blue);
      .case-studies {
        &__card-logo img {
          @media (min-width: 576px) {
            transform: scale(1.03);
          }
        }
      }
    }
    &-block {
      
    }
    &-logo {
      margin-bottom: ac(40px, 20px);
      height: 100%;
      max-height: 70px;
      height: 70px ;
      /* @media (max-width: 1024px) { */
      /* } */
      img {
        object-fit: contain;
        height: 100%;
        /* object-fit: cover; */
        /* aspect-ratio: 1; */
        transition: .3s all;
      }
    }
    &-title {
      font-weight: 500;
      font-size: ac(30px, 20px);
      color: var(--cl-black);
      margin-bottom: ac(20px, 16px);
      --lines: 2;
    }
    &-descr {
      font-weight: 400;
      font-size: ac(17px, 16px);
      color: rgba(10, 10, 10, 0.8);
      overflow: auto;
      padding-right: 5px;
      overflow: auto;
      max-height: 6lh;
      p {

      }
    }
    &-btn {
      margin-top: 16px;
      width: fit-content;
    }

  }
}