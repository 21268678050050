.studies {
  margin-bottom: ac(120px, 24px);
  &-wrap {
    padding: ac(32px, 12px);
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
    overflow: hidden;
    @media(max-width: 640px) {
      padding-top: 256px;
      justify-content: center;
    }
  }
  &-image {
    position: absolute;
    inset: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &-slider {
    position: relative;
    .swiper {
      margin: 0;
      overflow: hidden;
      max-width: 477px;
      border-radius: 0 10px 10px 10px;
      @media(max-width: 640px) {
        max-width: ac(477px, 303px, 375, 620);
      }
      @media(max-width: 374px) {
        max-width: ac(303px, 260px, 320, 375);
      }
    }
    &__arrows {
      flex-direction: column-reverse;
      position: absolute;
      top: 0;
      left: -57px;
      border-radius: 7px 0 0 7px;
      overflow: hidden;
      @media(max-width: 640px) {
        top: -57px;
        left: 0;
        flex-direction: row;
        border-radius: 7px 7px 0 0;
      }
    }
  }
  &-card {
    background-color: rgba(255, 255, 255, 0.8);
    padding: ac(40px, 16px);
    max-width: 477px;
    @media(max-width: 640px) {
      max-width: ac(477px, 303px, 375, 620);
    }
    @media(max-width: 374px) {
      max-width: ac(303px, 260px, 320, 375);
    }
    &__section-name {
      font-weight: 500;
      font-size: ac(38px, 30px);
      color: var(--cl-black);
      margin-bottom: ac(32px, 20px);
      font-weight: 600;
    }
    img {
      margin-bottom: ac(30px, 14px);
      max-width: ac(220px, 200px);
    }
    &__title {
      font-weight: 500;
      font-size: ac(30px, 28px);
      color: var(--cl-black);
      margin-bottom: ac(20px, 16px);
    }
    &__descr {
      font-weight: 400;
      font-size: 17px;
      color: var(--cl-black);
      margin-bottom: ac(53px, 32px);
      --lines: 6;
      @media (max-width: 576px) {
        line-height: 140%;
        --lines: 7;
      }
    }
    &__link {
      width: fit-content;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  &.studies-main-page {
    .studies {
      &-wrap {
        padding-top: ac(41px, 12px);
        padding-bottom: ac(41px, 12px);
        @media (max-width: 640px) {
          padding-top: ac(306px, 275px, 320, 640);
        }
      }      
      &-slider {
        .swiper {
          max-width: ac(477px, 420px);
          @media(max-width: 640px) {
            max-width: ac(477px, 303px, 375, 620);
          }
          @media(max-width: 374px) {
            max-width: ac(303px, 260px, 320, 375);
          }
        }
      }
      &-image {
        img {
          @media(max-width: 640px) {
            object-position: ac(-480px, -705px, 320, 640) 0px;
          }
        }
      }
    }
  }
  
  &.studies-case-study {
    margin-bottom: ac(90px, 24px);
    .studies {
      &-image {
        img {
          @media(max-width: 640px) {
            object-position: ac(0, -500px, 375, 640) 0px;
          }
        }
      }
      &-wrap {
        padding-top: ac(38px, 12px);
        padding-right: ac(38px, 12px);
        padding-bottom: ac(44px, 12px);
      }
    }
  }
  &.studies-solutions {
    margin-top: ac(45px, 20px);
  }
}