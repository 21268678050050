.team {
  background: var(--cl-navy);
  padding-top: ac(130px, 64px);
  padding-bottom: ac(137px, 64px);
  overflow: hidden;
  &-title {
    font-weight: 500;
    font-size: ac(70px, 32px);
    line-height: 1.28;
    color: #fff;
    margin-bottom: ac(100px, 32px);
    span {
      color: var(--cl-green);
    }
  }
  &-slider__arrows {
    margin-top: 20px;
    gap: 10px;
    .swiper-button {
      border-radius: 10px;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
  &-wrap {
    .swiper {
      overflow: visible;
      margin: 0;
      max-width: 613px;
      @media (max-width: 590px) {
        max-width: 335px;
      }
    }
  }
  &-card {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 590px) {
      flex-direction: column;
    }
    &__left {

    }
    &__image {
      max-width: 292px;
      margin-right: -20px;
      z-index: 1;
      position: relative;
      cursor: pointer;
      transition: .2s all;
      @media (max-width: 590px) {
        margin: 0;
        margin-bottom: -12px;
        max-width: ac(320px, 275px, 320, 590);
      }
      &:hover {
        transform: scale(1.04);
      }
      img {
        aspect-ratio: 1;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    &__link {
      position: absolute;
      bottom: -7px;
      left: 0;
      z-index: 2;
      cursor: pointer;
      @media (max-width: 590px) {
        position: relative;
        bottom: auto;
        left: 0;
        margin-top: 16px;
        .btn__text {
          background: var(--cl-navy)!important;
          color: #FFF!important;
        }
        .btn__icon {

        }
      }
    }
    &__info {
      border-radius: 10px;
      background: var(--cl-white);
      padding: ac(43px, 32px);
      padding-top: ac(24px, 24px);
      padding-bottom: 32px;
      min-width: ac(338px, 320px);
      @media (max-width: 590px) {
        padding: 18px;
        padding-top: 32px;
        max-width: 335px;
        width: 100%;
        min-width: auto;
      }
    }
    &__name {
      font-weight: 500;
      font-size: ac(47px, 28px);
      line-height: 1.17;
      color: var(--cl-black);
      margin-bottom: ac(28px, 18px);
      @media (max-width: 590px) {
        br {
          display: none;
        }
      }
    }
    &__social {
      margin-bottom: 13px;
      display: flex;
      width: fit-content;
      i {
        color: var(--cl-black);
        font-size: 32px;
        transition: .2s all;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &__contact {
      font-weight: 500;
      font-size: 15px;
      color: var(--cl-navy);
      background: var(--cl-sky-blue);
      padding: ac(7px, 6px) ac(15px, 12px);
      border-radius: 10px;
      display: block;
      width: fit-content;
      transition: .23s all ease-in-out;
      margin-bottom: ac(13px, 10px);
      &:hover {
        opacity: .83;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}