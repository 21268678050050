.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(44px, 38px);
  min-width: ac(44px, 38px);
  max-width: ac(44px, 38px);
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  i {
    font-size: ac(30px, 24px, 375);
    color: var(--cl-navy);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      /* border-color: var(--cl-blue); */
      transform: scale(1.05);
    }
  }

  &.pagination-next {
    margin-left: ac(16px, 10px, 375);
  }

  &.pagination-prev {
    margin-right: ac(16px, 10px, 375);
  }
}

.pagination-num {
  text-align: center;
  font-size: ac(20px, 18px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(48px, 40px);
  min-width: ac(48px, 40px);
  padding-left: 0px;
  padding-right: 0px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-second);

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cl-black);
    transition: all 0.3s ease;
  }

  &.active {
    cursor: not-allowed;
    border-color: var(--cl-blue);
    span {
      /* color: var(--cl-black-regular); */
    }
  }

  &.pointer-events-none {
    pointer-events: none;
    min-width: ac(40px, 32px);
    width: ac(40px, 32px);
    color: var(--cl-blue);
    margin: 0 ac(5px, 0);
  }

  &:hover {
    &:not(.active) {
      span {
        color: var(--cl-blue);
      }
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ac(64px, 42px);
  /* &.for-mob {
    .pagination-btn {
      height: ac(48px, 32px, 375);
      min-width: ac(48px, 32px, 375);
      max-width: ac(48px, 32px, 375);
    }

    .pagination-num {
      height: ac(48px, 32px, 375);
      min-width: ac(48px, 32px, 375);

      &.pointer-events-none {
        min-width: 32px;
        width: 32px;
      }
    }
  } */
}
