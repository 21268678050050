.case-study-article {
  padding-top: ac(70px, 42px);
  padding-bottom: ac(132px, 28px);
  .cont {
    max-width: 822px;
  }
  &__wrap {

  }
  &__buttons {
    margin-top: ac(70px, 18px);
    display: flex;
    gap: ac(31px, 20px);
    flex-wrap: wrap;
    .btn {
      @media (max-width: 576px) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}