.role-info {
  padding-bottom: ac(70px, 32px);
  &__wrap {
    display: flex;
    column-gap: ac(45px, 32px);
    row-gap: ac(30px, 28px);
    flex-wrap: wrap;
    background: var(--cl-green);
    padding: ac(30px, 20px);
    border-radius: 10px;
  }
  &__item {
    &-name {
      margin-bottom: ac(10px, 8px);
      font-weight: 400;
      font-size: ac(17px, 16px);
      color: var(--cl-black);
    }
    &-content {
      font-weight: 500;
      font-size: ac(23px, 20px);
      color: var(--cl-black);
    }
    &:last-child {
      /* width: 100%; */
    }
  }
}