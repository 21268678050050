.news {
  margin-bottom: ac(80px, 28px);
  &-wrap {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(ac(300px, 340px), 1fr)); */
    /* justify-items: center; */
    grid-template-columns: repeat(3, 1fr);
    gap: ac(26px, 18px);
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-card {
    border: 1px solid #81ccf4;
    border-radius: 16px;
    overflow: hidden;
    background: #FFF;
    max-width: 478px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: 640px) {
      max-width: none;
    }
    &:hover {
      .news-card__image img {
        transform: scale(1.1);
      }
    }
    &__image {
      overflow: hidden;
      aspect-ratio: 1.78 / 1;
      height: 100%;
      img { 
        transition: .2s all ease-in;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      padding: ac(32px, 18px);
      padding-top: ac(20px, 18px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__date {
      margin-bottom: ac(12px, 10px);
      font-weight: 600;
      font-size: 13px;
      color: var(--cl-blue);
    }
    &__title {
      font-weight: 500;
      font-size: ac(22px, 20px);
      color: var(--cl-black);
      margin-bottom: 8px;
      --lines: 2;
    }
    &__descr {
      font-weight: 400;
      font-size: 15px;
      color: rgba(10, 10, 10, 0.8);
      --lines: 2;
      margin-bottom: ac(22px, 18px);
      p {

      }
    }
    &__btn {
      width: fit-content;
    }
  }
}