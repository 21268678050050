a {
  display: inline-block;
}

.content-element {
  ol,
  ul {
    margin-bottom: ac(28px, 18px);
    li {
      margin-left: ac(26px, 20px);
      &:not(:last-child) {
        margin-bottom: ac(12px, 8px);
      }
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ul, ol,
  p {
    font-weight: 400;
    font-size: ac(17px, 16px);
    line-height: 1.45;
    color: var(--cl-black);
  }

  h1 {
    font-weight: 500;
    font-size: ac(32px, 24px);
    color: var(--cl-black);
    margin-bottom: ac(20px, 15px);
    padding-top: 16px;
  }
  h2 {
    font-weight: 500;
    font-size: ac(27px, 22px);
    color: var(--cl-black);
    margin-bottom: ac(18px, 14px);
    padding-top: 15x;
  }
  h3 {
    font-weight: 500;
    font-size: ac(23px, 20px);
    color: var(--cl-black);
    margin-bottom: ac(17px, 14px);
    padding-top: 14px;
  }
  h4 {
    font-weight: 500;
    font-size: ac(21px, 19px);
    color: var(--cl-black);
    margin-bottom: ac(17px, 14px);
    padding-top: 14px;
  }
  h5, h6 {
    font-weight: 500;
    font-size: ac(20px, 18px);
    color: var(--cl-black);
    margin-bottom: ac(17px, 14px);
    padding-top: 14px;
  }

  p {
    color: var(--cl-black);
    font-size: ac(17px, 16px);
    line-height: 1.45;
    a {
      color: var(--cl-blue);
      font-weight: 500;
      text-decoration: underline;
      text-decoration-color: transparent;
      text-underline-offset: -1px;
      transition: .2s all;
      &:hover {
        text-decoration-color: var(--cl-blue);
        text-underline-offset: 2px;
      }
    }
    &:not(:last-child) {
      margin-bottom: ac(21px, 12px);
    }
  }
}
