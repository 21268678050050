@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@500&display=swap');

:root {
  --cl-black: #0A0A0A;
  --cl-black-80: rgba(10, 10, 10, 0.8);

  --cl-navy: #052451;
  --cl-navy-80: rgba(5, 35, 81, 0.8);

  --cl-green: #1DD8DB;
  --cl-green-80: rgba(29, 216, 219, 0.8);

  --cl-blue: #4B5FEA;
  --cl-blue-80: rgba(75, 96, 234, 0.8);

  --cl-sky-blue: #81CCF4;
  --cl-sky-blue-80: rgba(129, 204, 244, 0.8);

  --cl-orange: #F77D48;

  --cl-primary: #ec612a;

  --cl-white: #ffffff;
  --cl-white-80: rgba(255, 255, 255, 0.8);

  --font-main: "Poppins", sans-serif;
  --font-second: "Figtree", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --header-height: ac(120px, 88px);

  --vh: calc(100vh / 100);

  --arw: 1;
  --arh: 1;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
