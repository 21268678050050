.banner {
  padding-top: ac(160px, 32px);
  padding-bottom: ac(97px, 72px);
  overflow: hidden;
  z-index: 13;
  .cont {
    @media(max-width: 920px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-wrap {
    border: 1px solid var(--cl-green);
    border-radius: 10px;
    position: relative;
    @media(max-width: 920px) {
      display: flex;
      flex-direction: column;
      max-width: 768px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  &-text {
    max-width: ac(680px, 430px, 1100);
    padding: ac(65px, 20px);
    padding-right: 0;
    @media(max-width: 920px) {
      padding-right: ac(65px, 20px);
      max-width: none;
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(47px, 28px);
    color: var(--cl-black);
    margin-bottom: ac(50px, 24px);
    max-width: ac(466px, 370px);
    @media(max-width: 920px) {
      max-width: 576px;
    }
    @media(max-width: 374px) {
      font-size: ac(28px, 24px, 320, 375);
    }
  }
  &-form {
    min-height: 170px;
    @media (max-width: 920px) {
      min-height: auto;
    }
    .meta-input {
      &.submit-type {
        position: relative;
        input {
          /* padding-right: ac(70px, 64px); */
          font-size: ac(21px, 18px);
        }
        .btn-small {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: ac(6px, 5px);
          /* .btn__icon {
            background-color: var(--cl-navy);
            i {
              color: var(--cl-white);
            }
          } */
        }
      }
    }
  }

  &-btn {
    cursor: pointer;
  }

  &-decoration {
    position: absolute;
    z-index: 1;
    top: 72px;
    right: -73px;
    visibility: visible;
    @media (max-width: 620px) {
      right: ac(-73px, -12px, 375, 620);
      top: ac(82px, 32px, 375, 620);
    }
    img {
      width: ac(596px, 460px, 920);
      height: 100%;
      object-fit: cover;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
      transform: rotate(3.7deg);
      max-width: 762px;
      z-index: 0;
      @media (max-width: 920px) {
        width: ac(600px, 300px, 320, 920);
      }
    }
    &__wrap {
      position: absolute;
      top: -30px;
      right: ac(-24px, 32px, 920, 1024);
      
      @media (max-width: 920px) {
        position: relative;
        right: auto;
        top: auto;
        bottom: -10px;
      }
    }
    &__bg {
      max-width: ac(500px, 450px, 920);
      @media (max-width: 920px) {
        position: relative;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    /* @media(max-width: 920px) {
      position: relative;
      top: auto;
      right: auto;
      transform: translate(ac(0px, 30px, 375, 576), 24px);
      max-width: ac(540px, 348px, 375, 920);
    } */
  }

  /* internals */
  &-button {
    .banner {
      &-wrap {
        background: #FFF;
      }
      &-text {
        padding-bottom: ac(75px, 20px);
      }
      &-btn {
        background: var(--cl-navy);
        padding: 16px ac(64px, 32px);
        width: fit-content;
        @media (max-width: 440px) {
          width: 100%;
        }
        .btn__text {
          color: #FFF;
          padding: 0;
          @media (max-width: 440px) {
            justify-content: center;
          }
        }
      }
    }
  }

  &-section-form {
    padding-top: 50px;
    padding-bottom: ac(130px, 72px);
    .btn {
      width: fit-content;
      cursor: pointer;
    }
    .banner {
      /* &-decoration {
        position: absolute;
        z-index: 1;
        right: -85px;
        top: 96px;
        @media (max-width: 620px) {
          right: ac(-85px, -12px, 320, 620);
          top: ac(96px, 32px, 320, 620);
        }
        img {
          width: ac(762px, 600px, 920);
          height: 100%;
          object-fit: cover;
          box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          transform: rotate(3.7deg);
          max-width: 762px;
          @media (max-width: 620px) {
            width: ac(600px, 300px, 320, 620);
          }
        }
        &__wrap {
          position: absolute;
          top: -40px;
          right: ac(-120px, -160px, 1025);
          @media (max-width: 1024px) {
            right: -120px;
          }
          @media (max-width: 920px) {
            position: relative;
            right: auto;
            top: auto;
            bottom: -10px;
          }
        }
        &__bg {
          max-width: ac(650px, 470px, 920);
          @media (max-width: 920px) {
            position: relative;
          }
        }
      }
      &-text {
        max-width: ac(610px, 470px, 1024);
        padding-bottom: ac(48px, 20px);
        @media (max-width: 920px) {
          max-width: none;
        }
      } */
      /* &-title {
        margin-bottom: ac(22px, 20px);
      } */
      /* &-form {
        &__row {
          display: flex;
          column-gap: 12px;
          @media (max-width: 520px) {
            flex-wrap: wrap;
          }
          .meta-input {
            width: 100%;
          }
        }
        .meta-input {
          margin-bottom: 12px;
          label {
            font-weight: 400;
            font-size: ac(17px, 16px);
            color: var(--cl-black);
          }
          input {
            height: 54px;
          }
        }
        &__bottom {
          margin-top: ac(24px, 20px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;
          .meta-checkbox {
            border: none;
            padding: 0;
            margin-bottom: 0;
            .meta-checkbox__box {
              --size: ac(24px, 20px);
              border-color: var(--cl-blue);
            }
            .meta-checkbox__text {
              font-weight: 400;
              font-size: 14px;
              line-height: 1.4;
              color: rgba(5, 36, 81, 0.8);
              max-width: 200px;
              a {
                text-decoration: underline;
                text-decoration-skip-ink: none;
                color: var(--cl-blue);
                transition: .2s all;
                &:hover {
                  text-decoration-color: transparent;
                }
              }
            }
          }
          .meta-checkbox input:checked ~ .meta-checkbox__box {
            background: var(--cl-blue);
          }
          .meta-checkbox input:checked ~ .meta-checkbox__box i {
            color: #FFF;
            font-size: 12px;
          }
        }
        &__submit {
          width: fit-content;
          @media (max-width: 440px) {
            width: 100%;
          }
        }
      } */
    }
  }
}