.first {
  padding-top: ac(206px, 112px);
  .cont {
    @media (max-width: 1024px) {
      padding-left: 23px;
    }
  }
  &-image {
    &__wrap {
      position: relative;
      z-index: -1;
      margin-top: -58px;
      max-height: 600px;
      overflow: hidden;
      @media (max-width: 768px) {
        margin-top: 13px;
      }
      img {
        width: 100%;
        height: 100%;
        min-width: 548px;
        object-fit: cover;
      }
    }
    img {
      object-position: ac(0px, 111px) ac(-140px, 0, 1440, 2560);
      @media (max-width: 768px) {
        object-position: ac(0px, -90px, 320, 470) ac(0px, -2px, 375, 768);
      }
    }
  }
  &-image__decoration {
    width: 102%;
    position: absolute;
    inset: 0;
    bottom: -11px;
    width: calc(100% + 40px);
    left: -20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      max-width: 768px;
      left: ac(0px, -60px, 320, 490);
      transform: translateX(0);
      /* left: ac(0px, -87px, 320, 576); */
    }
  }
  &-wrap {
    position: relative;
  }
  &-title {
    font-weight: 500;
    font-size: ac(82px, 40px);
    color: var(--cl-black);
    margin-bottom: 20px;
    @media (max-width: 768px) {
      line-height: 1.28;
    }
    @media (max-width: 374px) {
      font-size: ac(40px, 38px, 320, 375);
    }
  }
  &-subtitle {
    font-weight: 500;
    font-size: ac(40px, 22px);
    color: var(--cl-blue);
    margin-bottom: ac(100px, 38px);
    @media (max-width: 640px) {
      line-height: 144%;
    }
    span {
      color: var(--cl-orange);
    }
  }
  &-buttons {
    display: flex;
    gap: ac(40px, 24px);
    z-index: 2;
    position: relative;
    @media (max-width: 556px) {
      flex-direction: column;
    }
    &::before {
      content: '';
      width: 1900px;
      height: 108px;
      background: #FFF;
      position: absolute;
      left: ac(-1183px, -1363px);
      bottom: ac(-49px, -23px);
      border-radius: 0 0 20px 0;
      z-index: -1;
      @media (max-width: 576px) {
        width: 100vw;
        left: -20px;
      }
    }
    @media (max-width: 556px) {
      gap: 16px;
    }
  }
}

.first-index {
  overflow: hidden;
  .first {
    &-title {
      font-size: ac(73px, 41px);
      margin-bottom: ac(100px, 38px);
      @media (max-width: 767px) {
        font-size: ac(40px, 32px, 480, 768);
      }
      .default {

      }
      .js-typing {
        /* display: block; */
        color: var(--cl-green);
      }
      .typed-cursor {
        color: var(--cl-green);
      }
    }
  }
}

.first-internal {
  overflow: hidden;
  &__wrap {

  }
  &__title {
    font-weight: 500;
    font-size: ac(82px, 40px);
    color: var(--cl-black);
  }
  &__decor {
    position: absolute;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__image {
    &-decor {
      bottom: -11px!important;
      width: 103%;
      left: -25px;
    }
  }
  &__toggles {
    display: flex;
    align-items: center;
    gap: ac(6px, 4px);
    border: 1px solid #d2d2d2;
    border-radius: 7px;
    padding: 6px;
    width: fit-content;
    background: #FFF;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
    max-width: 100%;
    .swiper-wrapper {
      gap: ac(6px, 0px);
    }
  }
  &__toggle {
    font-weight: 500;
    font-size: ac(17px, 16px);
    color: var(--cl-black);
    padding: ac(16px, 12px) ac(20px, 16px);
    border-radius: 7px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: fit-content;
    &:hover {
      &::before {
        height: 5%;
        opacity: 0.7;
      }
    }
    &::before {
      content: '';
      height: 0%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: var(--cl-green);
      z-index: -1;
      transition: .22s all ease-in-out;
      opacity: 0.2;
    }
    &.active {
      &::before {
        height: 100%;
        opacity: 1;
      }
      &:hover {
        &::before {
          opacity: 0.85;
        }
      }
    }
  }

  &.first-internal-roles {
    padding-top: ac(206px, 120px);
    padding-bottom: ac(80px, 60px);
    .first-internal {
      &__decor {
        top: ac(-72px, -62px, 1440, 2560);
        right: ac(430px, -36px, 1440, 2500);
        max-width: ac(692px, 465px, 1440, 2560);
        width: 100%;
        
        @media (max-width: 1440px) {
          right: ac(-36px, -82px);
          max-width: ac(530px, 365px);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__title {
        margin-bottom: ac(58px, 20px);
        @media (max-width: 640px) {
          line-height: 1.3;
        }
      }
      &__form {
        .meta-input {
          position: relative;
          max-width: ac(620px, 580px);
          width: 100%;
          input {
            padding: ac(18px, 16px) ac(25px, 18px);
            padding-left: ac(19px, 14px);
            font-size: ac(21px, 18px);
            padding-right: ac(74px, 64px);
          }
        }
        button {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          right: ac(6px, 4px);
          &:hover {
            i {
              transform: translate(0) scale(1.05);
            }
          }
        }
      }
    }
  }

  &.first-internal-role-descr {
    padding-top: ac(210px, 120px);
    padding-bottom: ac(60px, 22px);
    .first-internal {
      &__title {
        font-weight: 500;
        font-size: ac(70px, 48px);
        color: var(--cl-black);
        margin-bottom: ac(22px, 16px);
        line-height: 1.4;
      }
      &__num {
        font-weight: 400;
        font-size: 15px;
        color: var(--cl-navy);
        margin-bottom: ac(40px, 22px);
      }
      &__btn {
        width: fit-content;
      }
      &__buttons {
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: ac(20px, 16px);
        @media (max-width: 400px) {
          flex-wrap: wrap;
          width: 100%;
          .btn {
            width: 100%;
          }
        }
        .btn {
          width: auto;
          &.btn-back {
            .btn__icon {
              transform: rotate(180deg);
            }
            &:hover {
              .btn__icon i {
                transform: translateX(4px);
              }
            }
          }
        }
      }
    }
  }

  &.first-internal-news {
    padding-top: ac(216px, 162px);
    padding-bottom: ac(441px, 483px);
    /* 385px, 435px */
    /* padding-bottom: ac(56px, 48px); */
    /* overflow: visible; */
    & + section {
      margin-top: ac(-385px, -435px);
    }
    .first-internal {
      &__decor {
        right: ac(0px, -270px, 1440, 2000);
        top: ac(0px, -76px);
        @media (max-width: 640px) {
          right: ac(-270px, -550px, 320, 640);
        }
        img {
          object-position: 0 0;
        }
      }
      &__wrap {

      }
      &__title {
        margin-bottom: ac(24px, 20px);
      }
    }
  }

  &.first-internal-cases {
    padding-top: ac(216px, 162px);
    padding-bottom: ac(56px, 48px);
    overflow: visible;
    .first-internal {
      &__decor {
        right: ac(0px, -225px, 1440, 2000);
        top: 0;
        img {
          object-position: 0 0;
        }
      }
      &__wrap {

      }
      &__title {
        margin-bottom: ac(24px, 20px);
      }
    }
  }

  &.first-internal-case-study {
    padding-top: ac(206px, 146px);
    .first-internal {
      &__decor {
        top: ac(-1606px, -960px);
        left: 50%;
        transform: translateX(-50%);
        width: ac(2335px, 1440px);
      }
      &__logo {
        margin-bottom: ac(50px, 32px);
        img {
          max-width: ac(198px, 140px, 640);
          width: 100%;
        }
      }
      &__title {
        margin-bottom: 0;
        font-size: ac(70px, 38px);
        @media (max-width: 374px) {
          font-size: ac(38px, 32px, 320, 375);
        }
      }
      &__image {
        aspect-ratio: 1.78 / 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          display: -webkit-inline-box;
        }
      }
    }
  }

  &.first-internal-about {
    padding-top: ac(200px, 140px);
    padding-bottom: ac(130px, 48px);
    .first-internal {
      &__wrap {
        margin-top: ac(6px, 0);
      }
      &__title {
        position: relative;
        z-index: 2;
        font-size: ac(81px, 38px);
        max-width: ac(530px, 300px);
        @media (max-width: 768px) {
          max-width: none;
        }
        &::before {
          content: "";
          z-index: -1;
          background: #fff;
          border-radius: 0 0 20px;
          width: ac(1840px, 1720px);
          height: 108px;
          position: absolute;
          bottom: clamp(-49px, 6.71429px - 3.86905vw, -23px);
          left: clamp(-1363px, 26.7857vw - 1568.71px, -1183px);
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 10;
        padding-top: ac(24px, 20px);

        &::before {
          content: "";
          z-index: -1;
          background: #fff;
          border-radius: 0 0 20px;
          width: ac(1840px, 1720px);
          height: 108px;
          position: absolute;
          bottom: 60px;
          transform: translateY(100%);
          left: clamp(-1363px, 26.7857vw - 1568.71px, -1183px);
        }
      }

      &__image {
        min-width: 470px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: ac(-50px, 0px, 1440, 2560) ac(-130px, 0px, 1440, 2560);
          @media (max-width: 470px) {
            object-position: ac(0px, -120px, 320, 470) 0 ;
          }
        }
        &-wrap {
          z-index: -1;
          max-height: 600px;
          margin-top: -58px;
          position: relative;
          overflow: hidden;
          @media (max-width: 768px) {
            margin-top: 25px;
          }
        }
        &-decor {
          position: absolute;
          inset: 0;
          width: calc(100% + 40px);
          left: -20px;
          @media (max-width: 768px) {
            max-width: 100%;
            left: 0;
          }
          img {
            object-fit: cover;
            width: 108%;
            min-width: 320px;
            height: 100%;
          }
        }
      }
    }
  }

  &.first-internal-solutions {
    padding-top: ac(200px, 132px);
    padding-bottom: ac(100px, 28px);
    .first-internal {
      &__wrap {
        margin-top: ac(6px, 0);
      }
      &__title {
        position: relative;
        z-index: 2;
        &::before {
          content: "";
          z-index: -1;
          background: #fff;
          border-radius: 0 0 20px;
          width: 1900px;
          height: 108px;
          position: absolute;
          bottom: clamp(-45px, 6.71429px - 3.86905vw, -23px);
          left: clamp(-1363px, 26.7857vw - 1568.71px, -1183px);
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 10;
        padding-top: ac(24px, 20px);

        &::before {
          content: "";
          z-index: -1;
          background: #fff;
          border-radius: 0 0 20px;
          width: 1900px;
          height: 108px;
          position: absolute;
          bottom: 60px;
          transform: translateY(100%);
          left: clamp(-1363px, 26.7857vw - 1568.71px, -1183px);
        }
      }

      &__image {
        min-width: 480px;
        img {
          object-position: ac(0px, -130px, 320, 480);
          width: 100%;
        }
        &-wrap {
          z-index: -1;
          max-height: 600px;
          margin-top: -58px;
          position: relative;
          overflow: hidden;
          @media (max-width: 768px) {
            margin-top: 25px;
          }
        }
        &-decor {
          position: absolute;
          inset: 0;
          width: calc(100% + 40px);
          left: -20px;
          @media (max-width: 768px) {
            max-width: 100%;
            left: 0;
          }
          img {
            object-fit: cover;
            width: 100%;
            min-width: 320px;
            height: 100%;
          }
        }
      }
    }
  }
}