.specialisms {
  background: var(--cl-orange);
  padding: ac(130px, 64px) 0;
  overflow: hidden;
  z-index: 1;
  &-decor {
    position: absolute;
    right: ac(-401px, -320px);
    bottom: ac(-232px, -200px);
    max-width: ac(1402px, 992px, 640);
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(70px, 32px);
    line-height: 1.28;
    color: #fff;
    margin-bottom: ac(70px, 32px);
  }
  &-slider__arrows {
    margin-top: 32px;
    gap: 10px;
    .swiper-button {
      border-radius: 10px;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
  &-wrap {
    .swiper {
      @media (max-width: 992px) {
        max-width: 292px;
        margin: 0;
        overflow: visible;
      }
      .swiper-wrapper {
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); */
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        justify-items: center;
        gap: ac(20px, 16px);
        @media (max-width: 992px) {
          display: flex;
          gap: 0;
        }
      }
    }
  }
  &-card {
    background: var(--cl-white);
    border-radius: 10px;
    overflow: hidden;
    transition: .25s all;
    transition-delay: .1s all;
    height: auto;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    &__nav {
      font-weight: 500;
      font-size: ac(23px, 20px);
      color: var(--cl-black);
      display: flex;
      align-items: center;
      min-height: ac(110px, 90px);
      background: var(--cl-green);
      border-radius: 10px;
      padding: 0 ac(16px, 14px);
    }
    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ac(38px, 28px) 0;
      position: relative;
      height: 100%;
    }
    &__image {
      max-width: 64px;
      img {
        width: 100%;
        height: 100%;
        max-height: 92px;
        object-fit: cover;
        transition: .2s all;
      }
    }
    &__content {
      display: none;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      background: var(--cl-navy);
      position: absolute;
      width: 100%;
      height: 100%;
      padding: ac(20px, 14px);
      bottom: -205%;
      transition: .35s all;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--cl-orange) transparent;
    }

    &:hover {
      /* background: var(--cl-navy); */
      .specialisms {
        &-card {
          /* &__image img {
            transform: scale(0.5);
            opacity: 0.3;
          } */
          /* &__content {
            bottom: 0;
          } */
        }
      }
    }
  }
}