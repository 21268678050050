@charset "utf-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input,
textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label,
button,
input[type="submit"],
input[type="button"] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  /*-moz-appearance: textfield;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 0;
  pointer-events: none;
  display: none;
}
