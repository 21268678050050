.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-navy) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  color: var(--cl-black);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-navy);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1246px;
  width: perc(1246);

  @media (max-width: 1024px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  font-family: var(--font-second);
  h1,h2,h3,h4,h5,h6 {
    color: var(--cl-blue);
  }
  h1 {
    font-weight: 900;
    font-size: ac(74px, 38px);
    line-height: 1.2;
    margin-bottom: ac(20px, 17px);
    &:not(:first-child) {
      padding-top: ac(20px, 16px);
    }
    &:first-child {
      margin-top: 0;
    }
  }
  
  h2 {
    font-weight: 900;
    font-size: ac(56px, 36px);
    line-height: 1.18;
    margin-bottom: ac(16px, 14px);
    &:not(:first-child) {
      padding-top: ac(20px, 16px);
    }
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: ac(48px, 32px);
    font-weight: 800;
    margin-bottom: ac(15px, 12px);
    &:not(:first-child) {
      padding-top: ac(14px, 10px);
    }
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    font-size: ac(44px, 28px);
    margin-top: ac(18px, 16px);
    margin-bottom: ac(18px, 16px);
    &:first-child {
      margin-top: 0;
    }
  }
  h5 {
    font-weight: 800;
    font-size: ac(40px, 24px);
    line-height: 112%;
    margin-top: ac(16px, 14px);
    margin-bottom: ac(18px, 16px);
    &:first-child {
      margin-top: 0;
    }
  }
  h6 {
    font-weight: 600;
    font-size: ac(26px, 24px);
    line-height: 1.36;
    margin-top: ac(17px, 16px);
    margin-bottom: ac(18px, 16px);
        &:first-child {
      margin-top: 0;
    }
  }

  img {
    border-radius: ac(24px, 20px);
    overflow: hidden;
    box-shadow: 0 0 120px 0 rgba(255, 255, 255, 0.52);
  }
  p {
    font-weight: 300;
    font-size: ac(20px, 18px);
    line-height: 1.48;
    font-family: var(--font-main);
    a {
      font-weight: 400;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      color: var(--cl-navy);
      word-break: break-word;
      transition: .2s all;
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }
  strong {
    font-weight: 500;
  }
  table {
    overflow: auto;
    width: 100%;
    display: block;
  }
  ol,
  ul {
    margin-top: ac(32px, 20px);
    margin-bottom: ac(20px, 18px);
    list-style-type: disc;
    padding-left: ac(30px, 20px);
    li {
      padding-left: 6px;
      font-weight: 300;
      font-size: ac(20px, 18px);
      line-height: 1.48;
      a {
        font-weight: 400;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        color: var(--cl-navy);
        word-break: break-word;
        transition: .2s all;
        &:hover {
          text-decoration-color: transparent;
        }
      }
      strong {
        font-weight: 600;
      }
    }
  }

  ol {
    li {
      overflow: hidden;
      margin-bottom: ac(22px, 18px);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(18px, 15px);
    }
  }
  ul li , ol li {
    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: ac(64px, 36px);
    font-weight: bold;
  }
  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lines);
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: calc(1.2em * var(--lines)); */
}

      
.text-animation {

  span {
    display: inline-block;
    opacity: 0;
    transform: translateY(5px) rotate(6deg);
    transition: transform 0.3s ease-in-out, opacity 0.4s ease-in-out;
  }

  &.show {
    span {
      transform: translateY(0px) rotate(0deg);
      opacity: 1;
    }
  }
}


.line-draw-animation {
  stroke-dasharray: 1448;
  stroke-dashoffset: 1448;
  animation: draw 3s forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
                  
.rotate-180 {
  transform: rotate(180deg);
}
