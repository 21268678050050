.footer {
  padding-top: ac(40px, 42px);
  padding-bottom: ac(40px, 24px);
  &-top {
    border-top: 1px solid var(--cl-green);
    border-bottom: 1px solid var(--cl-green);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    overflow: hidden;
    gap: ac(27px, 14px);
    @media (max-width: 992px) {
      justify-content: flex-start;
      gap: 27px;
    }
    @media (max-width: 820px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: none;
      gap: ac(24px, 20, 576, 820);
    }
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 32px;
      padding-bottom: 0;
    }
  }
  &-item {
    width: ac(292px, 255px, 1024);
    @media (max-width: 992px) {
      width: auto;
    }
    &__name {
      font-weight: 500;
      font-size: ac(30px, 20px);
      color: var(--cl-black);
      padding-bottom: ac(20px, 8px, 640);
      position: relative;
      z-index: -1;
      &:before {
        content: '';
        width: 100vw;
        height: 1px;
        background: var(--cl-green);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__content {
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      @media (max-width: 820px) {
        &:before {
          content: '';
          width: 100vw;
          height: 1px;
          background: var(--cl-green);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      @media (max-width: 576px) {
        padding: 8px 0;
      }
      a, div {
        font-weight: 400;
        font-size: ac(20px, 17px, 992);
        color: var(--cl-black);
        transition: .2s all;
      }
      a {
        &:hover {
          color: var(--cl-blue-80);
        }
      }
    }
  }

  &-body {
    margin-top: ac(40px, 32px);
    padding-bottom: ac(20px, 24px);
    border-bottom: 1px solid var(--cl-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-logo {
    max-width: 128px;
    width: 100%;
    transition: .2s all ease-in-out;
    &:hover {
      transform: scale(1.04);
    }
    @media(max-width: 640px) {
      max-width: 164px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &-list {
    display: flex;
    gap: ac(30px, 16px);
    flex-wrap: wrap;
    @media (max-width: 540px) {
      max-width: 380px;
      gap: 8px 0;
    }
    li {
      @media (max-width: 540px) {
        width: 50%;
      }
      &:hover {
        .footer-list__item {
          &::before {
            width: 100%;
            left: 0;
          }
        }
      }
    }
    &__item {
      font-weight: 500;
      font-size: 17px;
      color: var(--cl-black);
      position: relative;
      &::before {
        content: "";
        background: var(--cl-navy);
        width: 0;
        height: 1px;
        transition: all .3s;
        position: absolute;
        bottom: -4.5px;
        right: 0;
      }
      @media (max-width: 640px) {
        font-size: 18px;
      }
    }
  }

  &-bottom {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media (max-width: 710px) {
      flex-wrap: wrap;
    }
  }
  &-copy {
    font-weight: 300;
    font-size: 14px;
    color: var(--cl-black);
    @media (max-width: 440px) {
      line-height: 1.56;
      max-width: 220px;
    }
  }
  &__developer {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    @media (max-width: 710px) {
      order: 3;
      width: 100%;
    }
    img {
      object-fit: contain;
      transition: 0.3s;
      @media (max-width: 710px) {
        width: 150px;
      }
      &:hover {
        transform: scale(1.03);
      }
    }

  }
  &-links {
    display: flex;
    gap: ac(30px, 8px, 640);
    flex-wrap: wrap;
  }
  &-link {
    font-weight: 400;
    font-size: 14px;
    color: var(--cl-black);
    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 2px;
    transition: .2s all;
    display: flex;
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--cl-black);
    }
    @media (max-width: 374px) {
      font-size: ac(14px, 12px, 320, 374);
    }
  }
}