.framework {
  background: var(--cl-blue);
  position: relative;
  overflow: hidden;
  z-index: 1;

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: ac(530px, 320px);
    position: relative;
    padding-top: ac(180px, 72px);
    padding-bottom: ac(200px, 80px);

    @media (min-width: 1025px) {
      min-height: 100vh;
    }

    @media (max-width: 851px) {
      padding-left: 0;
      padding-top: 300px;
    }
  }

  .meta-circle-scroll-zone {
    position: absolute;
    top: ac(-130px, -64px);
    bottom: ac(-97px, -48px);
    left: -100px;
    right: -100px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .meta-circle-scroll-swiper-wr {
      height: 100%;
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: stretch;
      align-items: stretch;

      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        height: auto;
      }
    }

    @media (max-width: 851px) {
      display: none;
    }

    @media (min-width: 1441px) {
      left: calc((100vw - 1246px) / 2 * -1);
      right: calc((100vw - 1246px) / 2 * -1);
    }
  }

  &-circle {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: ac(-253px, -120px);
    z-index: 10;
    pointer-events: none;

    .meta-circle {
      position: relative;
      --size: ac(586px, 390px);
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);

      &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      &__wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform-origin: center;
        transform: rotate(0deg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        transition: ease-in-out 0.5s;
      }

      &__decor {
        position: absolute;
        inset: 20px;
        background-image: url('../../assets/static/circle-arrows-decor.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &:before {
          position: absolute;
          inset: 0;
          content: '';
          background-image: url('../../assets/static/circle-text-decors.svg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      &__line {
        width: 3px;
        height: ac(185px, 140px);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background: var(--cl-orange);
        z-index: -1;

        &:before {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateY(50%) translateX(-50%);
          --size: ac(12px, 8px);
          border-radius: 50%;
          background: var(--cl-orange);
          content: '';
          width: var(--size);
          height: var(--size);
        }
      }

      &__item {
        background: #c9cff9;
        border-radius: 50%;
        --size: ac(133px, 80px);
        width: var(--size);
        height: var(--size);
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        transform-origin: top;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: ease-in-out 0.5s;
        z-index: 50;
        cursor: pointer;
        pointer-events: all;
        &:before {
          position: absolute;
          inset: ac(-10px, -7px);
          border: 1px solid var(--cl-white);
          border-radius: 50%;
          opacity: 0;
          pointer-events: none;
          transition: 0.4s;
          content: '';
        }
        &:hover {
          &::before {
            transform: scale(1.06);
          }
        }

        &.active {
          opacity: 1;
          background-color: var(--cl-white);
          --size: ac(196px, 100px);

          &:before {
            opacity: 1;
          }

          .meta-circle__item-title {
            transform: scale(1.2);
          }
        }

        &:not(.active) {
          transition: .3s scale;
          &:hover {
            scale: 1.05;
          }
        }
      }

      &__item-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__item-title {
        text-align: center;
        font-size: ac(17px, 13.5px);
        color: var(--cl-black);
        transition: 0.3s;
        font-weight: bold;
      }

      &__center {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        --size: ac(236px, 120px);
        width: var(--size);
        height: var(--size);
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:before {
          position: absolute;
          inset: ac(-6px, -5px);
          border: ac(2px, 1px) solid var(--cl-white);
          border-radius: 50%;
          content: '';
          z-index: -1;
        }
      }

      &__center-content {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: var(--cl-orange);
        padding: 8px;
        --size: ac(158px, 100px);
        width: var(--size);
        height: var(--size);
      }

      &__center-title {
        text-align: center;
        color: var(--cl-white);
        font-weight: 500;
        font-size: ac(62px, 36px);
      }
    }

    @media (max-width: 851px) {
      top: 50px;
    }
  }

  &-content {
    max-width: 100%;
    @media (max-width: 851px) {
      margin-top: 32px;
    }
    &__nav {
      margin-bottom: ac(35px, 24px);
      display: flex;
      gap: ac(35px, 20px);
      align-items: center;
      &-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: ac(19px, 18px);
        line-height: 1.48;
        text-align: center;
        color: #fff;
        i {
          margin-top: 5px;
          font-size: ac(64px, 48px);
        }

        @media (max-width: 851px) {
          display: none;
        }
      }

      &-text {
        font-weight: 500;
        font-size: ac(70px, 36px);
        line-height: 1.16;
        color: #fff;
        span {
          color: var(--cl-green);
        }
      }
    }

    &__slider {
      width: 100%;
      max-width: 100%;

      .swiper {
        width: 100%;
        max-width: 100%;
        overflow: visible;

        @media (min-width: 852px) {
          pointer-events: none;
        }
      }

      .swiper-buttons {
        grid-column-gap: 12px;
        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }
    }

    &__item {
      border-radius: 12px;
      background: #fff;
      &-nav {
        padding: ac(28px, 20px) ac(48px, 24px);
        border-bottom: 1px solid var(--cl-blue);
      }
      &-title {
        font-weight: 600;
        font-size: ac(32px, 22px);
        color: var(--cl-navy);
      }
      &-body {
        padding: ac(40px, 20px) ac(48px, 24px);
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        gap: ac(20px, 14px);
        column-gap: ac(32px, 14px);
        &__item {
          position: relative;
          font-weight: 600;
          font-size: ac(19px, 16px);
          line-height: 1.68;
          color: var(--cl-navy);
          padding-left: ac(40px, 26px);
          width: calc(50% - ac(20px, 14px));
          &::before {
            content: '\e902';
            font-family: 'icomoon' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: var(--cl-orange);
            font-size: ac(32px, 20px);
            position: absolute;
            left: 0;
            top: 0;
          }

          @media (max-width: 1200px) {
            line-height: 1.25;
          }

          @media (max-width: 425px) {
            width: 100%;
          }
        }
      }
    }
  }

  &-decor {
    position: absolute;
    right: ac(20px, -420px, 1440, 2500);
    bottom: ac(-160px, -20px);
    z-index: -1;
    max-width: ac(1043px, 768px, 576);
    @media (max-width: 1440px) {
      right: ac(-420px, -300px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
