.approach {
  background: var(--cl-blue);
  padding-top: ac(130px, 40px);
  padding-bottom: ac(130px, 48px);
  position: relative;
  overflow: hidden;
  &-decoration {
    position: absolute;
    right: ac(-401px, -376px, 375);
    bottom: ac(-232px, -113px, 375);
    z-index: 0;
    max-width: ac(1402px, 684px, 375);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-nav {
    margin-bottom: ac(100px, 24px);
  }
  &-title {
    font-weight: 500;
    font-size: ac(70px, 28px);
    line-height: 1.28571;
    color: #fff;
    max-width: ac(928px, 380px);
    @media (max-width: 768px) {
      line-height: 1.4;
    }
    @media (max-width: 374px) {
      font-size: ac(28px, 25px, 320, 375);
    }
    span {
      color: var(--cl-green);
    }
  }
  /* swiper-wr */
  &-wrap {
    margin-bottom: ac(60px, 38px);
    .swiper {
      margin: 0;
      overflow: visible;
      @media (max-width: 1300px) {
        max-width: 290px;
      }
    }
    .swiper-wrapper {
      justify-content: space-between;
      @media (min-width: 1300px) {
        display: flex;
        gap: ac(26px, 20px);
      }
    }
  }
  &-slider__arrows {
    display: none;
    @media (max-width: 1300px) {
      display: flex;
      gap: 12px;
    }
    .swiper-button {
      border: 1px solid var(--cl-white);
      background: transparent;
      border-radius: 6px;
      color: var(--cl-white);
      --size: ac(57px, 48px);
      &::before {
        font-size: ac(30px, 24px);
      }
      &:hover {
        border-color: var(--cl-white-80);
        color: var(--cl-white-80);
      }
    }
  }

  &-card {
    padding: 26px;
    border-radius: 10px;
    background-color: var(--cl-navy);
    max-width: 290px;
    width: 100%;
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    flex-shrink: initial;
    &:last-child {
      margin-right: 0!important;
    }
    @media (max-width: 1300px) {
      flex-shrink: 0;
    }
    &:hover {
      .approach-card__image {
        img {
          transform: scale(1.05);
        }
      }
    }
    &__title {
      font-weight: 500;
      font-size: 23px;
      color: #fff;
      z-index: 2;
      position: relative;
    }
    &__descr {
      font-weight: 400;
      font-size: 17px;
      color: #fff;
      z-index: 2;
      position: relative;
      overflow: auto;
      max-height: 9lh;
      scrollbar-width: thin;
      scrollbar-color: var(--cl-blue) transparent;
      padding-right: 8px;
      letter-spacing: -0.3px;
    }
    &__image {
      position: absolute;
      inset: 0;
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .3s ease-in-out;
      }
    }

    &.partner-card {
      max-width: 398px;
      padding: ac(40px, 20px);
      justify-content: flex-start;
      min-height: ac(398px, 325px);
      height: auto;
      border-radius: 16px;
      .approach-card {
        &__title {
          font-weight: 600;
          font-size: ac(40px, 28px);
          line-height: 1.28;
          margin-bottom: ac(24px, 18px);
        }
      }
    }
  }

  &-btn {
    width: fit-content;
    position: relative;
    z-index: 2;
    @media (max-width: 576px) {
      width: 100%;
    }
    .btn__text {
      padding-right: ac(20px, 7px)!important;
      @media (max-width: 374px) {
        font-size: ac(16px, 14px, 320, 374);
        padding-left: ac(20px, 7px)!important;
        text-wrap: nowrap;
      }
    }
    &__wrap {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 16px;
      @media (max-width: 1300px) {
        justify-content: space-between;
        align-items: center;
      }
    }
  }

}