.clients {
  padding-top: ac(26px, 24px);
  margin-bottom: ac(100px, 48px);
  overflow: hidden;
  &__wrapper {

  }
  &__marquee {
    &:last-child {
      margin-top: ac(36px, 16px);
    }
    .js-marquee {
      display: flex;
      gap: ac(26px, 16px);
      margin-right: ac(26px, 16px)!important;
    }
  }
  &__logo {
    border: 1px solid var(--cl-green);
    border-radius: 10px;
    --size: ac(186px, 148px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    img {
      max-width: ac(150px, 110px, 640);
      width: 100%;
    }
  }
}