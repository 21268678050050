.breakdown {
  padding-top: ac(130px, 64px);
  padding-bottom: ac(138px, 64px);
  background: var(--cl-navy);
  margin-bottom: ac(65px, 32px);
  overflow: hidden;
  .cont {
    @media (max-width: 460px) {
      padding: 0;
    }
  }
  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: ac(70px, 38px);
    @media (max-width: 460px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(70px, 36px);
    line-height: 1.28;
    color: #fff;
    span {
      color: var(--cl-green);
    }
  }
  &-descr {
    max-width: ac(504px, 440px);
    font-weight: 400;
    font-size: ac(19px, 18px);
    line-height: 1.56;
    color: #fff;
  }

  &-wrap {
    display: flex;
    gap: ac(86px, 20px);
    justify-content: space-between;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 48px;
    }
  }
  &-tabs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 900px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
    @media (max-width: 460px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-tab {
    border: 2px solid var(--card-color);
    border-radius: 16px;
    padding: ac(28px, 16px) ac(32px, 18px);
    cursor: pointer;
    max-width: 398px;
    transition: .2s all;
    min-height: 192px;
    @media (max-width: 900px) {
      /* width: 3; */
      max-width: none;
    }
    &.active {
      box-shadow: 0 0 32px 0 var(--shadow-color);
      background: var(--card-color);
      .breakdown-tab {
        &__title, &__descr {
          color: var(--cl-navy);
        }
        &__decor {
          border-color: var(--cl-navy);
          &::before {
            background: var(--cl-navy);
          }
        }
      }
    }
    &:hover {
      border-radius: 20px;
      box-shadow: 0 0 12px 0 var(--shadow-color);
      .breakdown-tab {
        &__decor {
          transform: scale(1.05);
        }
      }
    }
    &__nav {
      margin-bottom: ac(24px, 18px);
      display: flex;
      align-items: center;
    }
    &__decor {
      --size: 36px;
      min-width: var(--size);
      width: var(--size);
      height: var(--size);
      border-radius: 100%;
      border: 1px solid var(--card-color);
      position: relative;
      transition: .2s all;
      &::before {
        content: '';
        min-width: 20px;
        width: 20px;
        height: 20px;
        background: var(--card-color);
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__title {
      font-weight: 700;
      font-size: ac(26.8px, 20px);
      color: var(--card-color);
      margin-left: ac(12px, 8px);
    }
    &__descr {
      font-weight: 400;
      font-size: 16px;
      color: #fff;
    }

    &:nth-child(3n + 1) {
      --card-color: #1DD8BD;
      --shadow-color: rgba(29, 216, 219, 0.65);
    }
    &:nth-child(3n + 2) {
      --card-color: #81CCF3;
      --shadow-color: rgba(129, 204, 243, 0.65);
    }
    &:nth-child(3n + 3) {
      --card-color: #F77D48;
      --shadow-color: rgba(247, 125, 72, 0.65);
    }
  }

  &-radar {
    max-width: ac(760px, 550px, 900);
    width: 100%;
    height: auto;
    min-height: 280px;
    top: 0px;
    left: 6px;
    position: relative;
    @media (max-width: 900px) {
      top: auto;
      left: auto;
      max-width: 720px;
      height: ac(500px, 300px, 375, 900);
    }
    /* @media (min-width: 460px) {
      height: auto;
    } */
    canvas#breakdownRadar {
      width: 100%!important;
      height: auto!important;
    }
  }
}