.role-descr {
  padding-bottom: ac(90px, 20px);
  padding-top: 3px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: ac(26px, 20px);
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 38px;
    }
  }
  &__content {
    width: 67%;
    @media (max-width: 768px) {
      width: 100%;
    }
    h3 {

    }
    p {
      &:first-child {
        margin-bottom: ac(40px, 20px);
      }
    }
    ul, ol {
      margin-bottom: ac(24px, 18px);
    }

    ul + p, ol + p {
      padding-top: ac(26px, 16px) ;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: ac(50px, 32px);
    width: fit-content;
    gap: ac(20px, 16px);
    @media (max-width: 400px) {
      flex-wrap: wrap;
      width: 100%;
    }
    .btn {
      width: auto;
    }
    .btn-back {
      width: fit-content;
      .btn__icon {
        transform: rotate(180deg);
      }
      &:hover {
        .btn__icon i {
          transform: translateX(4px);
        }
      }
      @media (max-width: 400px) {
        width: 100%;
      }
    }
  }
  &__apply {
    width: fit-content;
    @media (max-width: 400px) {
      width: 100%;
    }
  }

  &__sidebar {
    position: sticky;
    top: ac(80px, 60px);
    right: 0;
    height: fit-content;
    max-width: ac(398px, 290px);
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      max-width: none;
    }
  }
  &__card {
    padding: ac(30px, 18px);
    border-radius: 10px;
    border: 1px solid var(--cl-blue);
    display: flex;
    flex-direction: column;
    max-width: ac(398px, 290px);
    width: 100%;
    @media (max-width: 768px) {
      max-width: 400px;
    }
    @media (max-width: 576px) {
      width: 100%;
      max-width: none;
    }
    &-name {
      font-weight: 500;
      font-size: 23px;
      color: #0a0a0a;
      margin-bottom: ac(26px, 20px);
    }
    &-social {
      margin-bottom: 13px;
      width: fit-content;
      i {
        font-size: ac(32px, 28px);
        color: var(--cl-black);
        display: block;
        transition: .2s all;
      }
      &:hover {
        i {
          transform: scale(1.1);
        }
      }
    }
    &-link {
      padding: ac(7px, 6px) ac(15px, 10px);
      font-weight: 500;
      font-size: 15px;
      color: var(--cl-navy);
      border-radius: 10px;
      background: var(--cl-sky-blue);
      transition: .3s all;
      width: fit-content;
      &:not(:last-child) {
        margin-bottom: ac(13px, 10px);
      }
      &:hover {
        background: var(--cl-sky-blue-80);
      }
    }
  }
}