.sectors {
  padding-top: ac(20px, 24px);
  padding-bottom: ac(130px, 24px);
  .cont {
    @media (max-width: 640px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &-wrap {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    @media (max-width: 980px) {
      flex-direction: column;
      border-radius: 0;
      justify-content: center;
    }
  }
  &-text {
    background: var(--cl-navy);
    padding: ac(50px, 24px) ac(50px, 20px);
    padding-right: ac(75px, 20px, 992);
    width: 636px;
    border-radius: 10px 0 0 10px;
    position: relative;
    @media (max-width: 980px) {
      border-radius: 10px;
      width: 100%;
      max-width: ac(620px, 311px, 375, 980);
    }
    &::before {
      content: '';
      left: 99%;
      width: 100vw;
      height: 100%;
      background: var(--cl-navy);
      top: 0;
      position: absolute;
      @media (max-width: 980px) {
        display: none;
      }
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(70px, 40px);
    line-height: 1.285;
    color: #fff;
    margin-bottom: ac(20px, 24px);
    @media (max-width: 768px) {
      line-height: 1.24;
    }
  }
  &-subtitle {
    font-weight: 400;
    font-size: ac(20px, 18px);
    color: #fff;
    margin-bottom: 16px;
    overflow: auto;
    max-height: calc(4 * 1.5em);
    scrollbar-width: thin;
    scrollbar-color: var(--cl-sky-blue) transparent;
    @media(max-width: 980px) {
      max-height: none;
    }
  }
  &-descr {
    font-weight: 400;
    font-size: ac(17px, 15px);
    color: #fff;
    margin-bottom: ac(47px, 24px);
    overflow: auto;
    max-height: 7lh;
    scrollbar-width: thin;
    scrollbar-color: var(--cl-sky-blue) transparent;
    @media (pointer: coarse) {
      padding-right: 10px;
    }
    @media(max-width: 980px) {
      max-height: none;
      overflow: hidden;
    }
  }
  &-btn {
    width: fit-content;
    @media (max-width: 374px) {
      .btn__text {
        font-size: ac(16px, 14px, 320, 375);
      }
    }
  }

  &-slider {
    position: relative;
    margin-right: ac(-20px, 0, 1200);
    border-radius: 10px;
    @media (max-width: 640px) { 
      width: 100%;
    }
    .swiper {
      border-radius: 10px;
      overflow: hidden;
      max-width: ac(610px, 500px, 992);
      margin: 0;
      @media (max-width: 980px) {
        max-width: ac(666px, 330px, 375, 980);
      }
      @media (max-width: 640px) {
        max-width: none;
      }
    }
    &__arrows {
      position: absolute;
      top: ac(10px, 16px, 375);
      right: ac(17px, 4px, 375);
      background: var(--cl-sky-blue);
      z-index: 3;
      @media (max-width: 640px) {
        gap: ac(15px, 0px, 320, 374);
      }
      .swiper-button {
        background: transparent;
        border-color: transparent;
        --size: ac(57px, 45px)
      }
    }
  }
  &-card {
    max-width: ac(610px, 500px, 992);
    @media (max-width: 980px) {
      max-width: ac(666px, 330px, 375, 980);
    }
    @media (max-width: 640px) {
      max-width: none;
    }
    &__nav {
      background: var(--cl-sky-blue);
      background: var(--cl-sky-blue);
      border-radius: 10px;
      padding: 16px 0;
      padding-left: ac(42px, 16px, 640);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @media (max-width: 640px) {
        padding-top: 20px;
      }
    }
    &__title {
      font-weight: 500;
      font-size: ac(30px, 26px);
      color: var(--cl-navy);
      max-width: ac(420px, 360px, 980);
      @media(max-width: 980px) {
        max-width: ac(500px, 200px, 320, 980);
      }
    }
    &__image {
      border-radius: 10px;
      overflow: hidden;
      @media(max-width: 600px) {
        padding-top: 55px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
        @media (max-width: 980px) {
          aspect-ratio: 1.31 / 1;
        }
      }
    }
  }

}