.contact {
  padding-top: ac(206px, 150px);
  padding-bottom: ac(95px, 12px);
  overflow: hidden;
  position: relative;
  &-decor {
    position: absolute;
    z-index: -1;
    top: ac(-68px, 20px, 640);
    right: ac(-320px, -199px, 1440, 2560);
    width: ac(1820px, 1402px, 1440, 2560);
    @media (max-width: 1440px) {
      /* top: ac(-68px, 20px, 640); */
      right: ac(-199px, -130px, 640);
      width: ac(1402px, 420px, 640);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(100px, 48px);
    text-align: center;
    color: var(--cl-black);
    margin-bottom: ac(50px, 32px);
  }
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  &-info {
    margin-top: ac(80px, 32px);
    display: flex;
    justify-content: center;
    column-gap: ac(45px, 24px);
    row-gap: ac(30px, 20px);
    padding: ac(30px, 20px);
    background: var(--cl-blue);
    border-radius: 10px;
    flex-wrap: wrap;
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-name {
        font-weight: 400;
        font-size: ac(17px, 16px);
        text-align: center;
        color: #fff;
        margin-bottom: 10px;
      }
      &-content {
        font-weight: 500;
        font-size: ac(23px, 20px);
        text-align: center;
        color: #fff;
        display: flex;
        gap: ac(10px, 8px);
        align-items: center;
        transition: .2s all;
        position: relative;
        &::before {
          content: "";
          background: var(--cl-green);
          width: 0;
          height: 1px;
          transition: all .3s;
          position: absolute;
          bottom: -6.5px;
          right: 0;
        }
        &:hover {
          &::before {
            background: var(--cl-white);
            width: 100%;
            left: 0;
          }
        }
      }

      &-socials {
        .contact-info__item-content {
          &::before {
            display: none;
          }
        }
        a {
          &:hover {
            transform: scale(1.05);
          }
        }
        i {
          font-size: ac(32px, 28px);
        }
      }
    }
  }
}