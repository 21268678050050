.touch-us {
  /* overflow: hidden; */
  /* clip-path: inset(0% 0% -100% 0%); */
  z-index: 10;
  &__wrap {
    background: var(--cl-sky-blue);
    border-radius: 10px;
    padding: ac(68px, 54px) ac(80px, 20px);
    position: relative;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 640px) {
      align-items: flex-start;
    }
    @media (max-width: 374px) {
      padding: ac(48px, 40px, 320, 374) ac(20px, 16px, 320, 374);
    }
  }
  &__decoration {
    position: absolute;
    left: ac(-115px, -229px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    max-width: ac(642px, 485px, 375);
    &-cont {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      inset: 0;
    }
    @media (max-width: 640px) {
      top: 0;
      transform: translateY(0);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__nav {
    margin-bottom: ac(45px, 24px);
    z-index: 2;
    position: relative;
  }
  &__title {
    font-weight: 500;
    font-size: ac(47px, 28px);
    text-align: center;
    color: var(--cl-black);
    @media (max-width: 640px) {
      line-height: 1.4;
      text-align: left;
      max-width: 282px;
    }
  }
  .swiper {
    /* overflow: visible; */
    max-width: 65%;
    clip-path: inset(-1000% 0% -1000% 0%);
    z-index: 12;
    pointer-events: none;
    overflow-y: visible;
    padding-bottom: ac(220px, 180px);
    padding-top: ac(220px, 180px);
    @media (min-width: 901px) {
      margin: 0;
    }
    @media (max-width: 900px) {
      max-width: 100%;
      margin-bottom: ac(-440px, -360px);
    }
    .swiper-wrapper {
      pointer-events: none;
    }
  }
  &__form {
    margin-top: ac(-220px, -180px);
    margin-bottom: ac(-220px, -180px);
    max-width: 980px;
    width: 100%;
    z-index: 2;
    position: relative;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ac(20px, 14px);
    .thanks-text {
      padding: ac(220px, 180px) 0;
      line-height: 1.4;
      width: 100%;
    }
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
    }
    &.touch-us__contact {
      .swiper {
        /* max-width: 70%; */
        width: 100%;
      }
      .touch-us__bottom {
        .swiper-buttons {
          gap: ac(22px, 20px);
          justify-content: flex-end !important;
          @media (max-width: 340px) {
            justify-content: space-between !important;
            gap: ac(20px, 3px, 320, 340);
          }
          .touch-us__prev {
            .btn__text {
              border: 1px solid #d2d2d2;
              border-radius: 0px 7px 7px 0px;
            }
          }
          .touch-us__prev {
            &.swiper-button-disabled {
              opacity: 0.5;
              visibility: visible;
            }
          }
          .touch-us__btn,
          .touch-us__submit {
            @media (max-width: 375px) {
              width: 100%;
            }
          }
        }
      }
    }

    &.job-alerts {
      @media (max-width: 960px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
      }
      .swiper {
        @media (max-width: 960px) {
          /* max-width: 100%;
          margin-bottom: -800px; */
          max-width: 100%;
          margin-bottom: ac(-440px, -360px);
          overflow: visible;
        }
      }
      .touch-us {
        &__item-text {
          width: 62%;
          min-width: fit-content;
        }
      }
    }
  }
  &__step {
    width: 100%;
  }
  &__item {
    border: 1px solid #d2d2d2;
    background: #fff;
    border-radius: 7px;
    padding: ac(14px, 12px) 20px;
    display: flex;
    align-items: center;
    pointer-events: all;
    @media (max-width: 620px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 16px 20px;
    }
    &-text {
      min-width: ac(170px, 140px);
      font-weight: 400;
      font-size: ac(21px, 18px);
      color: var(--cl-black);
      width: 55%;
      padding-right: 6px;
      @media (max-width: 640px) {
        width: 100%;
      }
      @media (max-width: 374px) {
        font-size: ac(18px, 16px, 320, 375);
      }
    }
    .meta-input {
      width: 100%;
      padding-left: ac(20px, 12px);
      border-left: 1px solid #000;
      height: 40px;
      input {
        border: none;
        padding: 0;
        height: 100%;
        font-size: ac(21px, 18px);
        &:focus {
          border-color: var(--cl-navy);
          caret-color: var(--cl-navy);
          box-shadow: none;
        }
      }
    }
    .meta-select {
      padding-left: ac(20px, 12px);
      border-left: 1px solid #000;
      width: 100%;
      z-index: 32;
      .choices__inner {
        height: auto;
        min-height: auto;
        padding: ac(12px, 8px) ac(28px, 18px) !important;
      }
    }
  }

  &__bottom {
    padding-top: ac(220px, 180px);
    padding-bottom: ac(220px, 180px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: ac(822px, 800px); */
    /* width: 100%; */
    position: relative;
    width: fit-content;
    /* margin-top: ac(20px, 16px); */
    z-index: 2;
    .swiper-buttons {
      /* width: 100%; */
      display: flex;
      justify-content: space-between;
    }
  }
  &__btn {
    cursor: pointer;
    /* opacity: 0;
    visibility: hidden; */
    transition: 0.3s all;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &.swiper-button-disabled {
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        .btn__icon i {
          transform: translate(0);
        }
      }
    }
  }
  &__prev {
    &.swiper-button-disabled {
      /* opacity: 0; */
      /* visibility: hidden; */
    }
    .btn__icon {
      i {
        rotate: 180deg;
      }
    }
  }
  &__next {
    &.swiper-button-disabled {
      display: none;
    }
    &.swiper-button-disabled + .touch-us__submit {
      display: flex;
    }

    &.swiper-button-disabled + input + .touch-us__submit {
      display: flex;
    }
  }
  &__submit {
    display: none;
  }

  &.email-alerts {
    z-index: 1;
    .touch-us {
      &__title {
        color: #fff;
      }
      &__wrap {
        background: var(--cl-blue);
      }
    }
  }
}

.error-message {
  color: red;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: 2px;
}

.input-error {
  border-color: red;
}
