/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(32px, 18px);
  padding-top: ac(32px, 18px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: none;
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-white);
  border-radius: 8px;
  color: var(--cl-black);
  font-family: var(--font-main);
  box-shadow: 3px 7px 15px 3px rgba(0, 0, 0, 0.102);

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--cl-black);
  }

  #cc-nb-text {
    font-size: ac(18px, 17px);
    font-weight: 400;
    line-height: 156%;
    font-family: var(--font-second);
  }
  #cc-nb-title {
    color: var(--cl-black);
    font-weight: 400;
    font-size: ac(40px, 24px);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    /* background: var(--cl-navy);
    position: relative;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    z-index: 2;
    border: 1px solid var(--cl-navy);
    padding: 16px ac(24px, 20px);
    transition: all 0.3s ease;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize !important;
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 500;
    line-height: 132%;
    color: var(--cl-navy); */

    display: flex;
    justify-content: center;
    align-items: center;
    height: ac(58px, 48px) !important;
    border-radius: 7px;
    overflow: hidden;
    transition: 0.3s all;
    font-size: ac(17px, 16px);
    font-weight: 500;
    height: 100%;
    width: 100%;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ac(20px, 20px);
    color: var(--cl-white);
    background: var(--cl-navy);
    width: fit-content;
    border: 1px solid var(--cl-navy);
    @media (max-width: 640px) {
      justify-content: flex-start;
    }

    &:focus {
      box-shadow: none !important;
      border-color: var(--cl-navy) !important;
    }

    &:hover {
      background: transparent;
      color: var(--cl-black);
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    gap: ac(16px, 12px);
  }

  .cc-nb-changep {
    background: transparent;
    color: var(--cl-black);
    border: 1px solid var(--cl-navy);
    &:hover {
      background: var(--cl-navy);
      color: var(--cl-white);
      opacity: 1;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--cl-navy);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;

  &:hover {
    color: var(--cl-fog);
    text-decoration-color: transparent;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: none;
    border-radius: 0;
    overflow: hidden;
    box-shadow: 3px 7px 15px 3px rgba(0, 0, 0, 0.102);
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-white) !important;
    border-top-color: var(--cl-black) !important;
    border-bottom-color: var(--cl-black) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-white);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
  }

  .cc-cp-body-tabs-item {
    background: var(--cl-white);
    transition: all 0.3s ease;
    opacity: 1;
    border: 1px solid var(--cl-navy) !important;
    border-radius: 8px !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-black);
    }

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--cl-navy) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--cl-white);
    transition: all 0.3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-navy) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-white);
    }

    &:hover {
      opacity: 1;

      .cc-cp-body-tabs-item-link {
        color: var(--cl-white) !important;
      }
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-black);
      padding: 5px;
      background: var(--cl-white);
      cursor: pointer;
      border: 1px solid var(--cl-black);

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-navy);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-black);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-navy);
      text-decoration: underline;
      transition: all 0.3s ease;
      display: inline-block;

      &:hover {
        color: var(--cl-fog);
        text-decoration-color: transparent;
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    background: var(--cl-navy);
    position: relative;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    z-index: 2;
    border: 1px solid var(--cl-navy);
    padding: 16px ac(24px, 20px);
    transition: all 0.3s ease;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize !important;
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 500;
    line-height: 132%;
    border-radius: 7px;
    color: var(--cl-navy);
    border: 1px solid var(--cl-navy);
    &:focus {
      box-shadow: none !important;
      border-color: var(--cl-navy) !important;
    }

    &:hover {
      background-color: var(--cl-navy);
      color: var(--cl-white);
    }

    @media (max-width: 530px) {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {
  .cc-cp-body-content-entry-text {
    color: var(--cl-black);
    font-size: ac(18px, 17px);
    font-weight: 400;
    line-height: 156%;
    font-family: var(--font-second);
  }

  .cc-cp-body-content-entry-title {
    color: var(--cl-black);
    font-weight: 400;
  }

  .cc-pc-head-title-text {
    color: var(--cl-black);
    font-weight: 500;
  }

  .cc-pc-head-title-headline {
    color: var(--cl-black);
    font-weight: 400;
  }

  .cc-cp-foot-byline {
    color: var(--cl-black);
    font-weight: 500;
  }
}

.cc-custom-checkbox {
  label {
    font-weight: 500;
  }
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox + label {
  color: var(--cl-black);

  &:after,
  &:before {
    border-color: var(--cl-navy);
  }
}

.termsfeed-com---pc-dialog
  input[type='checkbox'].cc-custom-checkbox:checked
  + label::before {
  background: var(--cl-navy);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }

  .termsfeed-com---pc-dialog .cc-pc-head-lang {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    justify-content: space-between;

    select {
      max-width: 200px;
    }
  }
}
