.roles {
  padding-bottom: ac(130px, 64px);
  &-wrap {  
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 640px) {
      flex-direction: column;
      gap: 28px;
    }
  }

  &-sidebar {
    max-width: ac(398px, 220px, 640);
    width: 43%;
    @media (max-width: 640px) {
      width: 100%;
      max-width: none;
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      margin-bottom: ac(50px, 20px, 640);
      @media (max-width: 640px) {
        width: 100%;
      }
      &-nav {
        font-weight: 500;
        font-size: ac(17px, 16px);
        color: var(--cl-black);
        margin-bottom: ac(22px, 16px);
      }
      &-body {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .meta-select {
          width: 100%;
        }
        .meta-checkbox {
          margin-left: 1px;
          margin-bottom: 0;
          @media (max-width: 640px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &-content {
    width: 57%;
    @media (max-width: 1024px) {
      width: 62%;
    }
    @media (max-width: 640px) {
      width: 100%;
      max-width: none;
    }
  }
  &-card {
    padding: ac(30px, 20px) 0px;
    padding-right: ac(16px, 10px);
    border-bottom: 1px solid var(--cl-navy);
    display: block;
    max-width: 716px;
    width: 100%;
    &:first-child {
      border-top: 1px solid var(--cl-navy);
    }
    &__title {
      font-weight: 500;
      font-size: ac(23px, 20px);
      color: var(--cl-black);
      margin-bottom: ac(25px, 18px);
    }
    &__info {
      display: flex;
      gap: ac(34px, 18px);
      margin-bottom: ac(20px, 18px);
      &-item {
        font-weight: 400;
        font-size: ac(15px, 14px);
        color: var(--cl-black);
      }
      @media (max-width: 640px) {
        flex-wrap: wrap;
      }
    }
    &__link.btn {
      border-radius: 0 7px 7px 0;
      --size: ac(58px, 42px);
      .btn__icon {
        background-color: var(--cl-orange);
        i {
          color: var(--cl-white);
          font-size: ac(28px, 20px);
        }
      }
    }

    &:hover {
      .roles-card {
        &__link.btn {
          .btn__icon {
            i {
              transform: translateX(4px) scale(1.03);
            }
          }
        }
      }
    }
  }
}