/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: var(--header-height);
  &.absolute {
    position: absolute;
  }
  .cont {
    /* width: 100%; */
    max-width: ac(1420px, 1246px, 1400, 1600);
    width: perc(1420);
    @media (max-width: 1340px) {
      max-width: 1266px;
      width: perc(1246);
    }
    @media (max-width: 1024px) {
      max-width: 1246px;
      width: perc(1246);
    
      @media (max-width: 1024px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &.fixed {
    position: fixed;
  }


  &.scrolled {
    background: #FFF;
    &:not(.open-menu) {
      height: calc(var(--header-height) / 1.6);
      .logo {
        /* max-width: 180px; */
        transform: translateX(-8px);
        scale: 0.87;
        &:hover {
          scale: 1.04;
        }
        @mixin max-xs {
          max-width: 165px;
        }
      }
    }
  }


  .logo {
    height: auto;
    width: 100%;
    max-width: ac(152px, 142px);
    transition: transform 0.25s ease, max-width 0.25s ease, scale .3s ease;
    z-index: 100;
    transform: translateX(0);
    scale: 1;
    &:hover {
      transform: scale(1.04);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  /* &.scrolled {
    .logo {
      width: 90%;
    }
  } */

  /* base descktop navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ac(-4px, 0);
    &.active {
      margin-right: 11px;
      @media (pointer: coarse) {
        margin-right: 0;
      }
    }
  }

  .burger, .burger-wrap {
    display: none;
  }

  /* list, menu */
  .navbar-nav {
    display: flex;
    flex-grow: 1;
    position: relative;
    .menu-list {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-bg {
      display: none;
      position: absolute;
      right: -75px;
      height: auto;
      z-index: -1;
      opacity: 0.6;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
    /* min-width: 1200px */
    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      /* padding-left: ac(60px, 20px); */
      align-items: center;
      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .menu-list {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: ac(30px, 24px);
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .navbar-nav.active .menu {
    &-bg {
      @media (max-width: 1200px) {
        display: flex;
      }
      @media (max-width: 640px) {
        opacity: 1;
      }
      @media (max-width: 420px) {
        max-width: 414px;
      }
    }
  }

  .menu-item {
    position: relative;
    &__icon {
      display: none;
    }
    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  /* mobile menu addt content */
  .menu-additional-content {
    @media (min-width: 1200px) {
      /* margin-left: ac(30px, 24px); */
      /* .header-link {
        position: relative;
        transition: all 0.3s ease;
        cursor: pointer;
        height: auto;
        overflow: visible;
        &::before {
          content: '';
          position: absolute;
          bottom: -6.5px;
          right: 0;
          width: 0;
          height: 1px;
          background: var(--cl-navy);
          transition: all 0.3s ease;
        }
        &.active {
          &::before {
            width: 100%;
            left: 0;
          }
        }
    
        &:hover {
          color: var(--cl-black-80);
          &::before {
            width: 100%;
            left: 0;
            background: var(--cl-blue);
          }
        }
        .btn__icon {
          display: none;
        }
        .btn__text {
          padding: 0;
          color: var(--cl-black);
          background: transparent;
          font-size: 17px;
          font-weight: 500;
        }
      } */

      .btn {
        height: ac(52px, 48px);
      }
    }
    /* display: none; */
    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: auto;
      margin-left: 0;
      .btn {
        width: 100%;
      }
    }
  }

  /* &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  } */

  /* nav-list */
  .menu-link {
    position: relative;
    font-size: 17px;
    font-weight: 500;
    color: var(--cl-black);
    transition: all 0.3s ease;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      bottom: -6.5px;
      right: 0;
      width: 0;
      height: 1px;
      background: var(--cl-navy);
      transition: all 0.3s ease;
    }
    &.active {
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &:hover {
      color: var(--cl-black-80);
      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-blue);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-white);
    box-sizing: content-box;
    display: none;
    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

/* min-1200? */
@mixin mobile-menu-start-point {
  /* mobile menu */
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle,
    .burger-wrap {
      display: flex;
      margin-right: -2px;
    }


    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 46%;
      max-height: calc(var(--vh) * 100);
      height: calc(var(--vh) * 100);
      min-width: 320px;
      padding-top: ac(100px, 90px);
      z-index: -1;
      background: var(--cl-white);
      padding-right: 5px;
      padding-bottom: 0;
      transition: opacity 0.25s ease-out, clip-path 0.5s ease,
        background-color 0.4s ease 0.1s, border-radius 0.3s ease;
      clip-path: circle(25px at calc(100% - 45px) 45px);
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      pointer-events: none;
      &__image {
        position: absolute;
        inset: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      /* mobile menu active */
      &.active {
        pointer-events: auto;
        clip-path: circle(75%);
        opacity: 1;
        background-color: var(--cl-white);
        backdrop-filter: blur(12px);
      }

      /* animations */
      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }
      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      /* list, additional content */
      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        padding-right: 15px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;
        .simplebar-wrapper {
          flex: 1;
        }
        .menu-additional-content {
          padding-bottom: 50px;
        }
        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          @media (max-width: 1024px) {
            padding-left: ac(25px, 20px, 320, 1024)!important;
          }
          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 24px;
            position: relative;
            /* margin-bottom: 128px; */
            margin-bottom: 80px;
            &:after {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 0;
              width: 173px;
              height: 1px;
              background-color: var(--cl-white);
              content: '';
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-navy);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      /* mobile list links style */
      .menu-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding: ac(20px, 16px) ac(28px, 20px); */
        font-size: 26px;
        font-weight: 500;
        margin-left: 0;
        width: auto;
        color: var(--cl-black);
        &:before {
          height: 1px;
          bottom: -3px;
        }
        &:hover {
          color: var(--cl-black-80) !important;
        }
        .active {

        }
      }

      /* mobile list items style */
      .menu-item {
        width: auto;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }


      /* padding-bottom: 100px; */
      /* full mobile menu 640 */
      @mixin max-sm {
        width: 100%;
        height: calc(var(--vh) * 100);
        padding-bottom: 0;
        border-bottom-left-radius: 0;
        .menu {
          padding-left: 20px;
        }

        .menu-list {
          padding-top: 32px;
          align-items: flex-start!important;
          &::after {
            display: none;
          }
        }

        .menu-item {
          justify-content: center;
        }

        .menu-link {
          align-items: flex-start;
          width: fit-content;
        }

        .menu-additional-content {
          margin-top: auto;
          justify-content: flex-end;  
          padding-bottom: 50px;
          &__buttons {
          }
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */

/* burger */
.burger-wrap {
  display: flex;
  cursor: pointer;
  &:hover {
    .burger-text {
      span {
        scale: 1.02;
      }
    }
  }
}
.burger-text {
  font-size: 14px;
  color: var(--cl-navy);
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cl-green);
  border-radius: 6px 0 0 6px;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  background: #FFF;
  /* text = menu */
  &__closed {
    top: -100%;
    &.active {
      top: 50%;
    }
  }
  /* text = close */
  &__opened {
    top: 100%;
    &.active {

    }
  }
  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* top: -100%; */
    transition: .3s all;
    &.active {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.burger,
.menu-toggle {
  background: var(--cl-green);
  position: relative;
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  border: 1px solid transparent;
  width: 48px;
  height: 48px;
  border-radius: 0 6px 6px 0;
  /* base burger */
  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    position: relative;
    z-index: 2;
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms;
    stroke: var(--cl-navy);
    stroke-width: 3;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  /* active burger */
  &.active {
    &:before {
      opacity: 1;
    }
    .line {
      stroke: var(--cl-navy);
    }
    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -88px;
    }

    .bottom {
      stroke-dashoffset: -88px;
    }
  }

  /* base burger + hover */
  &:hover {
    .line {
      stroke: var(--cl-navy-80);
    }
  }

  /* active burger + hover */
  &.active {
    &:hover {
      .line {
        stroke: var(--cl-navy-80);
      }
    }
  }
}

/* open menu bg */
.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
