.text-section {
  padding-bottom: ac(100px, 32px);
  .cont {
    @media (max-width: 1024px) {
      padding-left: 23px;
    }
  }
  &__wrap {

  }
  &__text {
    font-weight: 500;
    font-size: ac(47px, 27px);
    color: var(--cl-blue);
    max-width: ac(928px, 768px);
    @media (max-width: 768px) {
      line-height: 140%;
    }
    @media (max-width: 374px) {
      font-size: ac(27px, 24px, 320, 375);
    }
    span {
      color: var(--cl-green);
    }
  }

  &.text-index {
    margin-top: ac(100px, 48px);
  }
  &.text-about {
    padding-bottom: ac(131px, 56px);
    .text-section {
      &__text {
        color: var(--cl-green);
        max-width: ac(1034px, 768px);
        @media (max-width: 374px) {
          font-size: ac(27px, 23px, 320, 375);
        }
        span {
          color: var(--cl-orange);
        }
      }
    }
  }
}