.believe {
  padding-bottom: ac(130px, 48px);
  &-wrap {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 840px) {
      flex-direction: column-reverse;
      /* gap: 32px; */
    }
  }

  &-text {
    padding: ac(50px, 24px);
    padding-right: ac(156px, 92px);
    background: var(--cl-blue);
    border-radius: 10px;
    width: 66%;
    @media (max-width: 840px) {
      max-width: 768px;
      width: 100%;
      padding-right: ac(50px, 24px);
    }
  }
  &-title {
    font-weight: 500;
    font-size: ac(70px, 32px);
    line-height: 1.28;
    color: #fff;
    margin-bottom: ac(20px, 18px);
    @media (max-width: 374px) {
      font-size: ac(32px, 28px, 320, 375);
    }
  }
  &-subtitle {
    font-weight: 400;
    font-size: ac(20px, 18px);
    color: #fff;
    margin-bottom: 15px;
  }
  &-content {
    font-weight: 400;
    font-size: ac(17px, 16px);
    /* overflow: auto;
    scrollbar-width: thin; */
    /* max-height: 13.8lh; */
    padding-right: 8px;
    p {
      color: #fff;
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 24px);
    }
  }

  &-image {
    margin-left: -80px;
    @media (max-width: 840px) {
      margin: 0;
    }
    img {
      border-radius: 10px;
      max-width: ac(504px, 430px, 992);
      aspect-ratio: 0.75 / 1;
      @media (max-width: 992px) {
        max-width: ac(430px, 340px, 840, 992);
      }
      @media (max-width: 840px) {
        max-width: 640px;
        width: 100%;
      }
    }
  }
}