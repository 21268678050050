:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }
    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }
    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }
    &.pos-t {
      top: 0;
      bottom: initial;
    }
    &.pos-r {
      right: 0;
      left: initial;
    }
    &.pos-b {
      top: initial;
      bottom: 0;
    }
    &.pos-l {
      right: initial;
      left: 0;
    }
    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }
    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }
    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }
    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: 66vh;
    padding-right: 10px;
    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);
      /* margin-right: -10px; */
      /* right: -10px; */
      /* z-index: 99999; */

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }
}
.apply-now-popup {
  .input-form {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: ac(8px, 6px);
      font-weight: 400;
      font-size: ac(17px, 16px);
      color: #0a0a0a;
    }
    input {
      padding: ac(14px, 8px) ac(19px, 15px);
      font-weight: 400;
      font-size: ac(17px, 16px);
      color: #0a0a0a;
      border: 1px solid #1dd8db;
      border-radius: 10px;
      background: #FFF;
      @mixin transparent-bg-input var(--cl-black);
      &::placeholder {
        color: rgba(10, 10, 10, 0.5);
      }
    }
  }
  .popup-form__bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  .meta-checkbox {
    padding: 0;
    border: none;
    .meta-checkbox__text {
      font-size: 16px;
      max-width: 380px;
    }
  }
  .contact-form-bottom__left {
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    border: 1px dashed #1dd8db;
    border-radius: 15px;
    span {
      font-size: 15px;
      color: var(--cl-navy);
      text-align: center;
    }
    label {
      font-weight: 500;
      cursor: pointer;
    }
    #cv-preview {
      font-size: 16px;
      color: var(--cl-navy);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 8px;
      max-width: 100%;
    }
  }
}
.popup-team {
  max-width: ac(1080px, 832px, 992);
  background: transparent;
  padding: 0;
  @media (max-width: 940px) {
    max-width: 90%;
  }
  .simplebar-track.simplebar-vertical {
    top: 107px;
    bottom: 45px;
    right: ac(55px, 32px);
    overflow: visible;
    z-index: 6; 
    background: var(--cl-sky-blue);
    border-radius: 0;
    @media (max-width: 940px) {
      top: ac(77px, 57px, 375);
      bottom: 25px;
      right: ac(42px, 25px, 375);
    }
    .simplebar-scrollbar {
      width: ac(8px, 4px, 375);
      left: ac(-2px, 0px, 375);
      background: var(--cl-blue);
      border-radius: 0;
    }
  }
  .popup__btn-close {
    z-index: 3;
    --size: ac(44px, 28px);
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    background: transparent;
    border: 1px solid var(--cl-black);
    top: ac(20px, 10px, 320);
    right: ac(25px, 5px, 375);
    transition: .2s all;
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 374px) {
      --size: ac(28px, 24px, 320, 374);
      right: ac(5px, 4px, 320, 374);
    }
    i {
      font-size: ac(12px, 10px);
      color: var(--cl-black);
    }
  }
  &__wrap {
    display: flex;
    align-items: stretch;
  }

  &__general {
    height: fit-content;
    transform: translateX(ac(15px, 0, 940));
    position: relative;
    z-index: 5;
    &-image {
      border-radius: 10px;
      overflow: hidden;
      max-width: ac(316px, 284px, 940);
      @media (max-width: 940px) {
        margin-bottom: 16px;
        max-width: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-info {
      background: var(--cl-navy);
      border-radius: 10px;
      padding: ac(24px, 16px, 940) ac(28px, 16px, 940);
      transform: translate(ac(25px, 4px), ac(-24px, -4px));
      position: relative;
      z-index: 5;
      max-width: ac(316px, 284px, 940);
      @media (max-width: 940px) {
        transform: translate(0);
        max-width: none;
      }
      @media (max-width: 374px) {
        background: transparent;
        padding: 0;
      }
      &__name {
        font-weight: 500;
        font-size: ac(32px, 24px);
        line-height: 1.2;
        color: #fff;
        margin-bottom: 8px;
        @media (max-width: 374px) {
          color: var(--cl-navy);
        }
      }
      &__position {
        font-weight: 500;
        font-size: 20px;
        color: var(--cl-orange);
        margin-bottom: ac(20px, 16px);
      }
      .team-card__contact {

      }
      &__linkedin {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        margin-top: 16px;
        width: fit-content;
        color: var(--cl-sky-blue);
        &:hover {
          i {
            transform: scale(1.05);
          }
        }
        i {
          transition: .2s all;
          font-size: ac(26px, 24px);
          color: var(--cl-sky-blue);
          margin-right: ac(12px, 8px);
        }
        @media (max-width: 374px) {
          color: var(--cl-navy);
          i {
            color: var(--cl-navy);   
          }
        }
      }
    }
    @media (max-width: 940px) {
      display: none;
    }
    &--mob {
      display: none;
      @media (max-width: 940px) {
        display: block;
        margin-bottom: ac(32px, 26px);
      }
    }
  }

  &__content {
    max-width: ac(780px, 550px, 980);
    max-height: ac(600px, 492px, 992);
    width: 100%;
    border-radius: 24px;
    background: #FFF;
    padding: ac(40px, 22px) ac(104px, 38px) ac(44px, 20px, 992) ac(88px, 30px, 992);
    position: relative;
    z-index: 1;
    /* overflow: hidden; */
    /* &::after {
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      width: 100%;
      height: ac(155px, 64px);
      bottom: 0;
      left: 0;
      z-index: 5;
      border-radius: 0 0 24px 24px;
    } */
    @media (max-width: 940px) {
      max-width: 100%;
      padding: ac(26px, 16px, 460, 940) 0 ac(20px, 14px, 460, 940) ac(20px, 14px, 460, 940);
      padding-right: ac(60px, 32px, 320, 940);
      max-height: 90vh;
    }
  }
  &__title {
    font-weight: 500;
    font-size: ac(40px, 24px);
    line-height: 1.28;
    color: var(--cl-black);
    margin-bottom: ac(24px, 18px);
  }

  &__text {
    h5 {
      font-weight: 500;
      font-size: ac(20px, 18px);
      line-height: 1.28;
      color: var(--cl-black);
      margin-bottom: ac(16px, 14px);
      &:not(:first-child) {
        padding-top: ac(20px, 18px);
      }
    }
    p {
      font-weight: 400;
      font-size: ac(19px, 16px);
      line-height: 1.6;
      color: var(--cl-black);
      &:not(:last-child) {
        margin-bottom: ac(14px, 10px);
      }
    }
    ul, ol {
      li {
        font-weight: 500;
        font-size: ac(17px, 16px);
        line-height: 1.5;
        color: #000;
        position: relative;
        list-style-type: none;
        padding-left: ac(39px, 25px, 375);
        &:not(:last-child) {
          margin-bottom: ac(12px, 10px);
        }
        @media (max-width: 374px) {
          padding-left: 0;
          list-style-type: disc;
        }
        img {
          position: absolute;
          left: 0;
          top: ac(-1.3px, 4px, 375);
          max-width: ac(29px, 20px);
          width: 100%;
          @media (max-width: 374px) {
            display: none;
          }
        }
      }
    }
    & *:last-child {

    }
  }
}
.download-popup {
  padding-right: ac(23px, 12px, 640);
  padding-left: ac(40px, 22px, 640);
  padding-top: ac(55px, 38px);
  .simplebar-track.simplebar-vertical {
    transform: translateX(0);
  }
  .meta-select {
    &.mb-open {
      height: auto !important;
      .choices {
        &.is-open {
          &:not(.is-flipped) {
            margin-bottom: 210px;
          }
        }
      }
    }
  }

  .download-thanks {
    a {
      color: var(--cl-blue);
      font-weight: 500;
      text-decoration: underline;
      text-decoration-color: transparent;
      text-underline-offset: -1px;
      transition: .2s all;
      &:hover {
        text-decoration-color: var(--cl-blue);
        text-underline-offset: 2px;
      }
    }
  }

  @media (max-width: 520px) {

  }
  &__title {
    color: var(--cl-black);
    font-size: ac(30px, 22px);
    font-weight: 500;
    margin-bottom: ac(24px, 16px);
  }
  .popup__btn-close {
    right: 13px;
    top: 15px;
  }
  .banner {
    &-form {
      /* padding-bottom: 42px; */
      &__row {
        display: flex;
        column-gap: 12px;
        @media (max-width: 520px) {
          flex-wrap: wrap;
        }
        .meta-input {
          width: 100%;
        }
      }
      .meta-select {
        min-height: 54px;
        height: 54px;
        .choices__inner {
          min-height: 54px;
          height: 54px;
          padding-left: ac(20px, 24px)!important;
        }
      }
      .meta-input {
        margin-bottom: 12px;
        label {
          font-weight: 400;
          font-size: ac(17px, 16px);
          color: var(--cl-black);
        }
        input {
          height: 54px;
        }
      }

      .contact-form-bottom__left {
        padding: 12px 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        border: 1px dashed #d2d2d2;
        border-radius: 15px;
        span {
          font-size: 15px;
          color: var(--cl-navy);
          text-align: center;
        }
        label {
          font-weight: 500;
          cursor: pointer;
        }
        #cv-preview {
          font-size: 16px;
          color: var(--cl-navy);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px 8px;
          max-width: 100%;
        }
      }
      &__bottom {
        margin-top: ac(24px, 20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .meta-checkbox {
          border: none;
          padding: 0;
          margin-bottom: 0;
          .meta-checkbox__box {
            --size: ac(24px, 20px);
            border-color: var(--cl-blue);
          }
          .meta-checkbox__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4;
            color: rgba(5, 36, 81, 0.8);
            max-width: 200px;
            @media (max-width: 440px) {
              max-width: none;
              width: 100%;
            }
            a {
              text-decoration: underline;
              text-decoration-skip-ink: none;
              color: var(--cl-blue);
              transition: .2s all;
              &:hover {
                text-decoration-color: transparent;
              }
            }
          }
        }
        .meta-checkbox input:checked ~ .meta-checkbox__box {
          background: var(--cl-blue);
        }
        .meta-checkbox input:checked ~ .meta-checkbox__box i {
          color: #FFF;
          font-size: 12px;
        }
      }
      &__submit {
        width: fit-content;
        @media (max-width: 440px) {
          width: 100%;
        }
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}