.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: ac(58px, 48px);
  border-radius: 7px;
  overflow: hidden;
  transition: .3s all;
  .btn__text {
    font-size: ac(17px, 16px);
    font-weight: 500;
    height: 100%;
    width: 100%;
    transition: .3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ac(20px, 20px);
    @media (max-width: 640px) {
      justify-content: flex-start;
    }
  }
  .btn__icon {
    min-width: ac(58px, 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: .3s all;
    i {
      font-size: ac(30px, 26px);
      transition: .3s all;
      color: var(--cl-navy);
    }
  }

  &:hover {
    .btn__text {
      opacity: 0.85;
    }
    .btn__icon {
      i {
        transform: translateX(4px);
      }
    }
  }

  &.btn-navy {
    .btn__text {
      color: var(--cl-white);
      background: var(--cl-navy);
    }
    .btn__icon {
      background: var(--cl-green);
    }
  }

  &.btn-white {
    .btn__text {
      padding: 0 ac(20px, 18px);
      color: var(--cl-black);
      background: var(--cl-white);
    }
    .btn__icon {
      background: var(--cl-green);
    }
  }

  &.btn-border {
    height: 48px;
    border-radius: 0;
    .btn__text {
      padding: 0 16px;
      color: var(--cl-black);
      background: transparent;
      border: 1px solid var(--cl-sky-blue);
      border-right: 0;
      border-radius: 5px 0 0 5px;
    }
    .btn__icon {
      background: transparent;
      width: 48px;
      min-width: 48px;
      border: 1px solid var(--cl-sky-blue);
      border-radius: 0 5px 5px 0;
      i {
        font-size: 21px;
      }
    }
  }

  &.btn-base {
    .btn__icon {
      display: none;
    }
    .btn__text {
      padding: 0px ac(64px, 20px);
      color: var(--cl-white);
    }
  }

  &.btn-small {
    --size: ac(58px, 54px);
    width: var(--size);
    height: var(--size);
    border-radius: 7px;
    .btn__icon {
      background-color: var(--cl-navy);
      i {
        color: var(--cl-white);
        font-size: 24px;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  /* gap: ac(16px, 12px); */
  position: relative;
  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    --size: 57px;
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    color: var(--cl-navy);
    transition: 0.3s;
    background-color: var(--cl-white);
    inset: auto;
    border: 1px solid #FFF;
    &::after {
      display: none;
    }
    &::before {
      content: "\e900";
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'icomoon' !important;
      font-size: ac(30px, 28px);
      margin-top: ac(1px, 0px);
    }
    &.swiper-button-prev {
      &::before {
        content: "\e900";
        rotate: 180deg;
      }
    }
    &:hover {
      /* border-color: var(--cl-navy-80); */
      /* background: var(--cl-navy, #346c50); */
      color: var(--cl-navy-80);
    }
    &.swiper-button-lock {
      display: none;
    }
    &.swiper-button-disabled {
      opacity: 1;
      &::before {
        opacity: .5;
      }
    }
  }
}